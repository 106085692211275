<div class="mainDiv">
  <div class="header">
    <span class="spacer"></span>
    <h5>Reset Password</h5>
    <span class="spacer"></span>
  </div>

  <div>
    <mat-form-field>
      <mat-label>New Password</mat-label>
      <input matInput placeholder="New Password" [(ngModel)]="newPassword" required
        [ngModelOptions]="{standalone: true}">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>Confirm Password</mat-label>
      <input matInput placeholder="Confirm Password" type="password" [(ngModel)]="confirmPassword" required
        [ngModelOptions]="{standalone: true}">
    </mat-form-field>
  </div>
  <button mat-raised-button class="btn btn-default" data-dismiss="modal" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button class="btnPwd" color="primary" (click)="checkPasswordSame()">Reset
  </button>
</div>