import { Input, SimpleChanges } from '@angular/core';
import { Component, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'oa-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnChanges {

  @Input()
  value: any;

  @Input()
  title: string;

  @Input()
  class: string;

  @Input()
  style: string;

  icon: any;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  init() {

    if (!this.value) {
      return;
    }

    let icon: any = {};
    if (typeof this.value === 'string') {
      if (this.value.startsWith('http')) {
        icon.url = this.value;
      } else if (this.value.startsWith('fa-')) {
        icon.fa = this.value.substring(3);
      } else if (this.value.startsWith('oa-')) {
        icon.oa = this.value.substring(3);
        this.class = this.class || 'x-md';
      } else if (this.value.startsWith('mat-')) {
        icon.mat = this.value.substring(4);
      } else {

        switch (this.value) {
          case 'add':
            icon.mat = 'add';
            this.title = this.title || 'New';
            break;
          case 'upload':
            icon.mat = 'upload';
            this.title = this.title || 'Upload';
            break;

          case 'remove':
            icon.mat = 'remove_circle_outline';
            this.title = this.title || 'Remove';
            break;

          case 'play':
            icon.mat = 'play_arrow';
            this.title = this.title || 'Start';
            break;

          case 'pause':
            icon.mat = 'pause';
            this.title = this.title || 'Pause';
            break;

          case 'team':
            icon.mat = 'people';
            this.title = this.title || 'Team';
            break;

          case 'expand':
            icon.mat = 'expand_more';
            this.title = this.title || 'Expand';
            break;

          case 'collapse':
            icon.mat = 'expand_less';
            this.title = this.title || 'Collapse';
            break;

          case 'done':
            icon.mat = 'done_all';
            this.title = this.title || 'Show Done';
            break;

          case 'draft':
            icon.mat = 'edit';
            this.title = this.title || 'Show Draft';
            break;

          case 'busy':
            icon.fa = 'fa-spinner fa-spin';
            this.title = this.title || 'Processing';
            break;

          case 'error':
            icon.mat = 'warning';
            this.title = this.title || 'Errored';
            break;

          case 'edit':
            icon.mat = 'edit';
            this.title = this.title || 'Edit';
            break;

          case 'details':
            icon.mat = 'chevron_right';
            this.title = this.title || 'Details';
            break;

          case 'automatic':
            icon.mat = 'auto_mode';
            this.title = this.title || 'Automatic';
            break;

          case 'more':
            icon.mat = 'more_vert';
            this.title = this.title || 'More';
            break;

          case 'copy':
            icon.mat = 'content_copy';
            this.title = this.title || 'Copy';
            break;

          case 'download':
            icon.mat = 'save_alt';
            this.title = this.title || 'Download';
            break;

          case 'clear':
          case 'close':
          case 'back':
            icon.mat = 'close';
            this.title = this.title || 'Close';

            break;

          case 'search':
            this.title = this.title || 'Search';
            icon.mat = 'search';
            break;

          case 'filters':
          case 'filter':
            this.title = this.title || 'Toggle Filters';
            icon.mat = 'filter_list';
            break;

          case 'grid':
            this.title = this.title || 'Grid';
            icon.mat = 'grid_view';
            break;

          case 'columns':
            this.title = this.title || 'Column';
            icon.mat = 'view_column';
            break;

          case 'rows':
            this.title = this.title || 'Rows';
            icon.mat = 'view_list';
            break;

          case 'refresh':
            this.title = this.title || 'Reload';
            icon.mat = 'refresh';
            break;

          case 'help':
            this.title = this.title || 'Help';
            icon.mat = 'help_outline';
            break;

          default:
            icon.mat = this.value;
            break;
        }
      }
    } else {
      icon = this.value;
    }

    this.icon = icon;
    this.title = this.title || icon.title;
  }

}
