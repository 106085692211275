<div class="mainDiv">
  <div class="header">
    <span class="spacer"></span>
    <!-- <h5>Reset Password</h5> -->
    <span class="spacer"></span>
  </div>
  <form type="submit" ng-form="form">
    <div *ngIf="change" class="form-container flex-row">
      <mat-form-field appearance="outline">
        <label for="password">Current Password</label>
        <input matInput placeholder="" type="password" id="password" name="password" [(ngModel)]="password"
          [ngModelOptions]="{standalone: true}">
      </mat-form-field>
    </div>
    <div class="form-container flex-row">
      <mat-form-field appearance="outline">
        <label for="newPassword">New Password</label>
        <input matInput placeholder="" type="password" id="newPassword" name="newPassword" [(ngModel)]="newPassword"
          [ngModelOptions]="{standalone: true}">
      </mat-form-field>
    </div>
    <div class="form-container flex-row">
      <mat-form-field appearance="outline">
        <label for="confirmPassword">Confirm Password</label>
        <input matInput placeholder="" id="confirmPassword" name="confirmPassword" type="password"
          [(ngModel)]="confirmPassword" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
    </div>
    <button mat-raised-button class="btnPwd" type="submit" color="primary" (click)="checkPasswordSame()">Update
    </button>
    <button mat-raised-button class="btn btn-default" data-dismiss="modal" (click)="onNoClick()">Reset</button>
  </form>

</div>