<processing-indicator *ngIf="isProcessing"></processing-indicator>

<div class="flex-1-3" *ngIf="properties">

  <div class="profile-image-editor">
    <div>
      <img *ngIf="properties.profile.pic && properties.profile.pic.url" [src]="properties.profile.pic.url"
        (click)="openThumbnailSelector()">
    </div>
    <div *ngIf="!properties.profile.pic || !properties.profile.pic.url" (click)="openThumbnailSelector()"
      class="default">
      Picture</div>
    <i (click)="openThumbnailSelector()" class="md picture-upload"> </i>
  </div>

  <div class="flex-row two">
    <oa-input label="Email" type="email" [(value)]="properties.email" [required]="true" [validate]="emailDoesNotExist"
      (errored)="error = $event" [options]="{format: {trim: true}}">
    </oa-input>
    <oa-input label="Phone" type="phone" [(value)]="properties.phone" [required]="true" [validate]="mobileDoesNotExist"
      (errored)="error=$event" [options]="{format: {trim: true}}">
    </oa-input>

    <oa-input label="First Name" type="text" [(value)]="properties.profile.firstName" [required]="true"
      [validate]="validationService.isNameValid" (errored)="error = $event" [options]="{format: {trim: true}}">
    </oa-input>

    <oa-input label="Last Name" type="text" [(value)]="properties.profile.lastName" [required]="true"
      [validate]="validationService.isNameValid" (errored)="error = $event" [options]="{format: {trim: true}}">
    </oa-input>


    <div>
      <label for="type">Role<span class="mandatory">*</span></label>
      <mat-select id="type" name="type" [(value)]="properties.type.code" [disabled]="readonly" [required]="!readonly"
        (selectionChange)="setRoleType($event.value)">
        <mat-option disabled="" value=" ">Select a role</mat-option>
        <mat-option [value]="item.code" *ngFor="let item of types">{{item.name}}</mat-option>
      </mat-select>
    </div>

    <div>
      <directory-user-picker label="Supervisor" placeholder="Search Supervisor" [isAgent]="true"
        (changed)="onSupervisorSet($event)" [value]="properties.supervisor">
      </directory-user-picker>
    </div>

  </div>
</div>