<processing-indicator *ngIf="isProcessing"></processing-indicator>
<ng-template [ngIf]="items.length" [ngIfElse]="noData">
  <table mat-table [dataSource]="items" *ngIf="view === 'table'">
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td class="pointer" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
        [class.processing]="item.isProcessing">{{item.status | uppercase}}
      </td>
    </ng-container>
    <ng-container matColumnDef="personName">
      <th mat-header-cell *matHeaderCellDef>Contact Person</th>
      <td class="pointer" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
        [class.processing]="item.isProcessing">{{item.meta.contactPerson}}
      </td>
    </ng-container>
    <ng-container matColumnDef="orgName">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td class="pointer org-name" (click)="select(item)" mat-cell *matCellDef="let item"
        [class.deleted]="item.isDeleted" [class.processing]="item.isProcessing">
        <img *ngIf="item.logo && item.logo.url" [src]="item.logo.url">
        <img *ngIf="!item.logo || !item.logo.url" src="/assets/images/customers/default.png">
        <span>{{item.name}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
        [class.processing]="item.isProcessing">
        {{item.email || '--'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
        [class.processing]="item.isProcessing">
        {{item
        .phone || '--'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted" [class.processing]="item.isProcessing">
        <button mat-icon-button [disabled]="item.isProcessing" matTooltip="Edit" (click)="select(item)">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns, sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
  <app-paginator [pager]="pager" [total]="pager.total"></app-paginator>
</ng-template>
<ng-template #noData>
  <oa-no-data-found [message]="'No ' + type + ' Found'"></oa-no-data-found>
</ng-template>
