<oa-action *ngIf="trigger" [item]="trigger"></oa-action>

<div *ngIf="show && view !== 'modal'" class="dialog-container" [class.blocking]="true">
  <div class="dialog-content">
    <div class="dialog-body">
      <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
    </div>
  </div>
</div>

<ng-template #bodyTemplate>
  <ng-content></ng-content>
</ng-template>


<!-- <div class="modal" tabindex="-1" *ngIf="showDialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Modal body text goes here.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> -->