<div class="list">
  <ng-container *ngIf="items && items.length">
    <div *ngFor="let item of items" class="controls-row list-item">
      <oa-avatar [value]="item.profile"></oa-avatar>
      <div class="spacer">
        <div class="controls-row">
          <div class="spacer">
            <div>{{item.profile.firstName}} {{item.profile.lastName || ''}}</div>
            <!-- <span class="sub-text">{{item.email}}</span> -->
          </div>
          <div>
            <div *ngIf="item.lastSeen">
              <div>{{item.lastSeen | date: 'dd-MM-yyyy'}}</div>
              <span class="sub-text">{{item.lastSeen | date: 'HH:mm'}}</span>
            </div>
            <div *ngIf="!item.lastSeen">
              <div>Never</div>
              <span class="sub-text">Logged In</span>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </ng-container>
  <oa-no-data-found *ngIf="!items || !items.length"></oa-no-data-found>
</div>
