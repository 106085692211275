<div class="maps">
  <!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoomInput" (zoomChange)="zoomChangeNumber($event)">
    <agm-marker *ngFor=" let data of mapItems; let i=index" [iconUrl]="{
        url: data.icon.url,
        scaledSize: {height: data.icon.height ,width: data.icon.width},
        anchor : {x:7, y:7}
      }" [latitude]="data.latitude" [label]="data.label" [longitude]=" data.longitude">
      <agm-info-window *ngIf="data.companyName">
        <strong>{{data.companyName}}</strong>
      </agm-info-window>
    </agm-marker>
  </agm-map> -->
</div>
