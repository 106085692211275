<processing-indicator *ngIf="isProcessing"></processing-indicator>
<table mat-table [dataSource]="items" *ngIf="view === 'table'">
  <ng-container matColumnDef="pic">
    <th mat-header-cell *matHeaderCellDef>Pic</th>
    <td mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted" [class.processing]="item.isProcessing">
      <oa-avatar (click)="select(item)" [value]="item.from"></oa-avatar>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted" [class.processing]="item.isProcessing">
      <a (click)="select(item)">{{item.from.profile.firstName }} {{item.from.profile.lastName}}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="subject">
    <th mat-header-cell *matHeaderCellDef>Subject</th>
    <td mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted" [class.processing]="item.isProcessing">
      <a (click)="select(item)">{{item.subject}}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="timeStamp">
    <th mat-header-cell *matHeaderCellDef>TimeStamp</th>
    <td mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted" [class.processing]="item.isProcessing">
      <a (click)="select(item)">{{item.date | time: 'merged'}}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted" [class.processing]="item.isProcessing">
      <button mat-icon-button *ngIf="item.status !== 'archived'" [disabled]="item.isProcessing" matTooltip="archive">
        <mat-icon>archive</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-row *matRowDef="let row; columns: ['pic', 'name', 'subject', 'timeStamp', 'action'];"></tr>
</table>

<mat-list *ngIf="view === 'list'">
  <a mat-list-item (click)="select(item)" class="list-group-item" *ngFor="let item of items">
    <h4 mat-line>{{item.subject}}</h4>
    <p mat-line>{{item.from.profile.firstName}} {{item.from.profile.lastName ||''}}</p>
  </a>
</mat-list>
