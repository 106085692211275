<ng-container *ngIf="!reset">
  <div class="flex-row centered section-selector"><b>FORGOT PASSWORD</b></div>
  <div class="flex-row centered section-selector forgot-password">Enter your Email address and we'll send you a link
  </div>
  <div class="flex-row centered section-selector forgot-password">for reset
    your password </div>
  <div class="form-container">
    <oa-input label="Email Address" type="email" [(value)]="email" [required]="true" [validate]="validateEmail"
      (errored)="error = $event" [options]="{format: {trim: true}}">
    </oa-input>


    <!-- <mat-form-field>
      <mat-icon matPrefix><i class="mobile"></i></mat-icon>
      <mat-label [class.error]="mobileError">Type your mobile number</mat-label>
      <input matInput [(ngModel)]="mobile" type="tel" name="mobile" required (change)="validateMobile()">
      <mat-icon *ngIf="mobile && mobileError === ''" matSuffix color="primary">check</mat-icon>
      <mat-hint class="error" *ngIf="mobileError">{{mobileError}}</mat-hint>
    </mat-form-field> -->

    <!-- <mat-form-field *ngIf="currentOrganization">
      <mat-icon matPrefix><i class="code"></i></mat-icon>
      <mat-label>Type your code</mat-label>
      <input matInput [(ngModel)]="code" type="text" name="user-code" (change)="validateCode()">
      <mat-icon *ngIf="code && codeError === ''" matSuffix color="primary">check</mat-icon>
      <mat-hint class="error" *ngIf="codeError">{{codeError}}</mat-hint>
    </mat-form-field> -->

    <div class="recaptcha-div">
      <oa-recaptcha (token)="token = $event"></oa-recaptcha>
    </div>

    <div class="footer">
      <!-- <span class=" spacer"></span> -->
      <!-- <button mat-button [disabled]="isProcessing" (click)="cancel()">Cancel</button> -->
      <button mat-raised-button color="primary" [disabled]="isProcessing || error" (click)="create()">Reset
        Password
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="reset">
  <!-- <div *ngIf="!isInvalidOTP" class="otp-banner success">
    <span>OTP sent to your registered E-mail address!</span>
  </div>
  <div *ngIf="isInvalidOTP" class="otp-banner error">
    <span>Invalid OTP!</span>
  </div> -->

  <!-- <oa-input label="Enter OTP" type="otp" [(value)]="otp" [required]="true"></oa-input> -->
  <div class="flex-row centered section-selector forgot-password"><b>Please enter your new password</b></div>
  <section>
    <oa-input label="New Password" type="password" [(value)]="query.password" [required]="true"
      (errored)="error = $event" [validate]="validationService.isPasswordValid" [options]="{format: {trim: true}}">
    </oa-input>

    <oa-input label="Confirm Password" type="password" [(value)]="query.confirmPassword" [required]="true"
      (errored)="error = $event" [validate]="isConfirmPasswordValid" [options]="{format: {trim: true}}">
    </oa-input>

  </section>

  <div class="controls-row">
    <span class="spacer"></span>
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="isProcessing || error" (click)="confirm()">Confirm</button>
  </div>
</ng-container>