<div class="btn-pair">
  <button mat-icon-button (click)="onPrevious()" [disabled]="current === 1">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <button mat-icon-button (click)="onNext()" [disabled]="current === total">
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>
</div>

<!-- <div class="btn-pair">
  <button mat-icon-button (click)="onPrevious()"  [disabled]="current === 1">
    Pervious
  </button>
  <button mat-icon-button class="numbers">1</button>
  <button mat-icon-button class="numbers">2</button> 
  <button mat-icon-button class="numbers">3</button>
  <button mat-icon-button (click)="onNext()" [disabled]="current === total">
    Next
  </button>
</div> -->