<!-- <div class="main w3l">
    <h2>OOPS</h2>
    <h1> ERROR 404</h1>
    <h3>"Sorry ! the page you are looking for can't be found"</h3>
    <a href="#" class="back">BACK TO HOME</a>
    <div class="social-icons w3">
        <ul>
            <li>
                <a class="twitter" href="#"></a>
            </li>
            <li>
                <a class="facebook" href="#"></a>
            </li>
            <li>
                <a class="pinterest" href="#"></a>
            </li>
        </ul>
    </div>
</div> -->
<div class="main">
    <div class="header">
        <h5>ERROR 404</h5>
        <span class="spacer"></span>
        <button mat-raised-button color="primary" routerLink="/home/dashboard">Home</button>
    </div>
    <div class="error-container">
        <i class="not-found"></i>
        <div>Sorry ! the page you are looking for can't be found</div>
    </div>
</div>