<ng-container [ngSwitch]="control">

  <ng-container *ngSwitchCase="'oa-autocomplete'">
    <oa-autocomplete [label]="label" [showLabel]="showLabel" [style]="style" [class]="class" [placeholder]="placeholder"
      [value]="value" [url]="config.url" [readonly]="readonly" [disabled]="disabled"
      (changed)="onAutoCompleteSelect($event)" [options]="{
      search: config.search,
      templates: {
        value: valueTemplate
      },
      prefixItem: config.prefixItem
      }"></oa-autocomplete>
    <ng-container *ngTemplateOutlet="errorTemplate; context:{item: item}"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'selector'">
    <div [style]="style" [class]="class">
      <label *ngIf="showLabel" [for]="label">{{label}}</label>
      <mat-select [placeholder]="placeholder" [(ngModel)]="item.value" (selectionChange)="onSelectorChange($event)"
        [disabled]="disabled">
        <mat-option *ngFor="let option of items" [value]="option.value || option">
          {{option.label || option.value || option}}
        </mat-option>
      </mat-select>
      <ng-container *ngTemplateOutlet="errorTemplate; context:{item: item}"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'select'">
    <div [style]="style" [class]="class">
      <label *ngIf="showLabel" [for]="label">{{label}}</label>
      <mat-select [placeholder]="placeholder" [value]="value" (selectionChange)="onReportSelectorChange($event)"
        [disabled]="disabled">
        <mat-option *ngFor="let option of items" [value]="option.value || option">
          {{option.label || option.value || option}}
        </mat-option>
      </mat-select>
      <ng-container *ngTemplateOutlet="errorTemplate; context:{item: item}"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'oa-icon-toggler'">
    <oa-icon-toggler [value]="item.value" [type]="type" [items]="items" (changed)="onToggle($event)">
    </oa-icon-toggler>
  </ng-container>

  <ng-container *ngSwitchCase="'showIconOnly'">

    <div class="controls-row" matTooltip="{{item?.value?.name | name}} | {{item?.value?.date | date : 'dd MMM yyyy'}}">
      <div class="spacer"></div>
      <div>
        <mat-icon>{{(item?.value?.code === 'automatic') ? 'auto_mode' : 'engineering'}}</mat-icon>
      </div>
      <div class="spacer"></div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'oa-unit-picker'">
    <oa-unit-picker [type]="type" [value]="item.value" (changed)="setUnit($event)" [readonly]="readonly">
    </oa-unit-picker>
  </ng-container>

  <ng-container *ngSwitchCase="'oa-input-selector'">
    <oa-input-selector [label]="label" [showLabel]="showLabel" [style]="style" [class]="class"
      [placeholder]="placeholder" [view]="type" [value]="item.value" [items]="items"
      (valueChange)="onValueChange($event)" [required]="required" [readonly]="readonly" [validate]="item.validations"
      [disabled]="disabled" [options]="{ }">
    </oa-input-selector>
  </ng-container>

  <ng-container *ngSwitchCase="'oa-input-range'">
    <oa-input-range [label]="label" [showLabel]="showLabel" [style]="style" [class]="class" [type]="type"
      [value]="item.value" (valueChange)="onValueChange($event)" [required]="required" [readonly]="readonly"
      [validate]="item.validations" [disabled]="disabled" (errored)="error = $event" [options]="{ format: format }">
    </oa-input-range>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <oa-input [label]="label" [showLabel]="showLabel" [style]="style" [class]="class" [placeholder]="placeholder"
      [type]="type" [value]="item.value?.value" (valueChange)="onValueChange($event)" [required]="required"
      [readonly]="readonly" [validate]="item.validations" [disabled]="disabled" (errored)="error = $event" [options]="{
          format: format
        }"></oa-input>
  </ng-container>
</ng-container>

<ng-template #errorTemplate let-item="item">
  <oa-error [dirty]="isFormSubmit" [type]="item.control" [value]="item.value" [label]="label"
    [validate]="item.validations" [required]="item.isRequired" (errored)="setError($event,item.key)">
  </oa-error>
</ng-template>

<ng-template #valueTemplate let-value="value">
  <ng-container [class.readonly]="readonly" [ngSwitch]="config?.templates?.value">
    <ng-container *ngSwitchCase="'portValueViewer'">
      <div class="controls-row" [matTooltip]="value.name | name">
        <div class="win protFlag" *ngIf="value?.address?.countryFlag">{{value?.address?.countryFlag}}</div>
        <div class="capitalize">{{value.code}}</div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'iconNameViewer'">
      <oa-avatar [url]="value?.logo?.url || value?.pic?.url" [view]="'avatar'" [value]="value?.name"></oa-avatar>
    </ng-container>

    <ng-container *ngSwitchDefault>
      {{value.label || value.name || value.code || value || ''}}
    </ng-container>
  </ng-container>

</ng-template>
