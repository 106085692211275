<!-- <table class="table">
  <thead>
    <tr>
      <th class="index">#</th>
      <th style="width:115px">Date</th>
      <th>Params</th>
      <th style="width:125px">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of items" class="capitalize {{item.status}}" (click)="select(item)">
       <td>{{ pageSize * (pageNo - 1) + i +1 }}</td>
      <td>
        <span>{{item.date | date: 'dd-MM-yyyy'}}</span>
        <br>
        <span class="sub-text">{{item.date | date: 'HH:mm'}}</span>
      </td>
      <td>
        <div class="params">
          <span class="badge-holder" *ngFor="let param of item.params">
            {{param.label}} <span class="badge badge-primary">{{param.valueLabel}}</span>
          </span>
        </div>
      </td>
      <td class="status" [ngSwitch]="item.status">
        <ng-container *ngSwitchCase="'new'">
          <button mat-icon-button (click)="getReportLists()">
            <i class="fa fa-pause" style="color: #a1a1a1;" aria-hidden="true"></i>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'in-progress'">
          <button mat-icon-button (click)="getReportLists()">
            <i class="fa fa-clock-o" style="color: #38b6ff;" aria-hidden="true"></i>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'ready'">
          <button mat-icon-button (click)="download(item.url, item.type)">
            <mat-icon>cloud_download</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'errored'">
          <i class="fa fa-exclamation-triangle" style="color: #D8000C;" aria-hidden="true"></i>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table> -->

<!-- <div class="custom_pagination">
  <label>Total Records: {{reports.totalRecords}}</label>
  <div class="pageSize">
    <label>Page Size:</label>
    <select name="pageSize" [(ngModel)]="reports.pageSize" (change)="reports.pageNo = 1;getReportLists()">
      <option value="10">10</option>
      <option value="50">50</option>
      <option value="100">100</option>
      <option value="500">500</option>
    </select>
  </div>
</div> -->

<processing-indicator *ngIf="isProcessing"></processing-indicator>

<table mat-table [dataSource]="items">

  <ng-container matColumnDef="date">
    <th class="head1" mat-header-cell *matHeaderCellDef>Date</th>
    <td class="td1" mat-cell *matCellDef="let item" (click)="select(item)">
      <span>{{item.date | date: 'dd-MM-yyyy'}}</span>
      <br>
      <span class="sub-text">{{item.date | date: 'HH:mm'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="params" class="params">
    <th class="head1" mat-header-cell *matHeaderCellDef>Filter Selected</th>
    <td class="td1" mat-cell *matCellDef="let item" (click)="select(item)">
      <div class="params">
        <span class="badge-holder" *ngFor="let param of item.params">
          {{param.label}} <span class="badge badge-primary">{{param.valueLabel}}</span>
        </span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="status" class="status">
    <th class="head1" mat-header-cell *matHeaderCellDef>Status</th>
    <td class="td1" mat-cell *matCellDef="let item">
      <ng-container [ngSwitch]="item.status">
        <ng-container *ngSwitchCase="'new'">
          <button mat-icon-button (click)="getReportLists(); select(item)">
            <i class="fa fa-pause" style="color: #a1a1a1;" aria-hidden="true"></i>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'in-progress'">
          <button mat-icon-button (click)="getReportLists(); select(item)">
            <i class="fa fa-clock-o" style="color: #38b6ff;" aria-hidden="true"></i>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'ready'">
          <button mat-icon-button (click)="download(item.url, item.type); select(item)">
            <mat-icon>cloud_download</mat-icon>
          </button>
          <button mat-icon-button (click)="sendEmail(item)">
            <mat-icon>mail</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'errored'">
          <i class="fa fa-exclamation-triangle" style="color: #D8000C;" aria-hidden="true"></i>
        </ng-container>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['date', 'params', 'status']; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: ['date', 'params', 'status'];"></tr>
</table>

<app-paginator [pager]="pager" [total]="pager.total"></app-paginator>