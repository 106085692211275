<ng-container [ngSwitch]="view">
  <ng-container *ngSwitchCase="'date'">
    <label *ngIf="label && showLabel" [ngClass]="{'required':required}">{{label}}</label>
    <!-- <mat-form-field appearance="outline">
      <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [(ngModel)]="date"
        (dateInput)="updated($event)" (dateChange)="updated($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> -->

    <div class="oa-input" [class.inline]="options.inline" [ngStyle]="options.style" (click)="openPicker()">
      <input class="hidden" [readonly]="readonly" [min]="minDate" [max]="maxDate" [matDatepicker]="picker"
        (dateInput)="updated($event)">
      <div class="input">{{date}} <span class="sub-text">{{!date ? placeholder : ''}}</span></div>
      <mat-icon *ngIf="!options.inline" class="subtle">date_range</mat-icon>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'HolidayFilter'">
    <label *ngIf="label && showLabel" [ngClass]="{'required':required}">{{label}}</label>
    <!-- <mat-form-field appearance="none">
      <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [(ngModel)]="date"
        (dateInput)="updated($event)" (dateChange)="updated($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> -->

    <div class="oa-input" [class.inline]="options.inline" (click)="openPicker()">
      <input #fromInput style="width: 0;" matInput [readonly]="readonly" [min]="minDate" [max]="maxDate"
        [matDatepickerFilter]="myHolidayFilter" [matDatepicker]="picker" (dateInput)="updated($event)">
      <!-- <div class="input">{{date}} <span class="sub-text">{{!date ? placeholder : ''}}</span></div> -->
      <mat-icon *ngIf="!options.inline" class="subtle">date_range</mat-icon>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'icon'">
    <input class="hidden" [min]="minDate" [max]="maxDate" [matDatepicker]="picker" (dateInput)="updated($event)">
    <div class="oa-icon md x" (click)="openPicker()" [class.active]="value">
      <div class="month">{{value || now | date: 'MMM'}}</div>
      <div class="day">{{value || now | date: 'd'}}</div>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </ng-container>

  <!-- <ng-container *ngSwitchCase="'date'">
    <mat-form-field>
      <mat-label>{{placeholder}}</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="date" (dateInput)="updated($event)"
        (dateChange)="updated($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </ng-container> -->

  <div class="controls-row" *ngSwitchCase="'range'">
    <mat-form-field>
      <mat-label>Start</mat-label>
      <input matInput [matDatepicker]="pickerStart" [(ngModel)]="range.start" (dateInput)="updatedStart($event)"
        (dateChange)="updatedStart($event)">
      <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
      <mat-datepicker #pickerStart></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Finish</mat-label>
      <input matInput [matDatepicker]="pickerFinish" [(ngModel)]="range.finish" (dateInput)="updatedFinish($event)"
        (dateChange)="updatedFinish($event)">
      <mat-datepicker-toggle matSuffix [for]="pickerFinish"></mat-datepicker-toggle>
      <mat-datepicker #pickerFinish></mat-datepicker>
    </mat-form-field>
  </div>

  <ng-container *ngSwitchCase="'week'">
    <div class="week">
      <div>
        {{ month }}</div>
      <div class="controls-row">
        <button mat-icon-button (click)="showPreviousWeek()">
          <mat-icon class="subtle">arrow_back</mat-icon>
        </button>
        <!-- <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true" (click)="showPreviousWeek()">chevron_left
      </mat-icon> -->
        <div class="oa-icon md x" *ngFor="let item of dates" (click)="click(item)" [class.active]="item === value">
          <div class="month">{{item | time: "ddd"}}</div>
          <div class="day">{{item | time: "DD" }}</div>
        </div>
        <button mat-icon-button (click)="showNextWeek()">
          <mat-icon class="subtle">arrow_forward</mat-icon>
        </button>
        <!-- <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true" (click)="showNextWeek()">chevron_right
      </mat-icon> -->

        <!-- <div class="clear"></div> -->
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'day'">
    <div class="day">
      <div class="controls-row">
        <button mat-icon-button (click)="showPreviousDate()">
          <mat-icon class="subtle">arrow_back</mat-icon>
        </button>
        <span style="margin-top: 10px;
        white-space: nowrap;">{{date | date: 'dd/MM/yyyy'}}</span>
        <input matInput class="form-control" [matDatepicker]="picker" (dateInput)="updated($event)"
          (dateChange)="updated($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <button mat-icon-button (click)="showNextDate()">
          <mat-icon class="subtle">arrow_forward</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'month'">
    <div class="controls-row month">
      <button mat-icon-button (click)="showPreviousMonth()">
        <mat-icon class="subtle">arrow_back</mat-icon>
      </button>
      <div class="bor">
        <input class="form-control" type="month" [value]="month" (change)="updated($event)" />
      </div>
      <button mat-icon-button (click)="showNextMonth()">
        <mat-icon class="subtle">arrow_forward</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'inline'">
    <div class="oa-input" [class.inline]="options?.inline" [ngStyle]="options?.style" [ngClass]="options?.class"
      (click)="openPicker()" (mouseover)="isSelected=true" (mouseout)="isSelected=false">
      <input #inputContainer class="hidden" [class.selected]="isSelected" [readonly]="readonly" [min]="minDate"
        [max]="maxDate" [matDatepicker]="picker" (dateInput)="updated($event)">
      <label *ngIf="placeholder && !date">{{placeholder}}</label>
      <div class="input">{{date}}</div>
      <mat-icon [class]="isSelected?'subtle inline-date-picker':'inline-date-picker'">date_range</mat-icon>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'readonly'">
    <div class="dateText">{{date ? date : value | date: format}}</div>
  </ng-container>

  <ng-container *ngSwitchCase="'weekAndData'">
    <div class="weekAndData">
      <div class="controls-row">
        <button mat-icon-button (click)="showPreviousSeven()">
          <mat-icon class="subtle">chevron_left</mat-icon>
        </button>
        <div class="oa-icon xx-md" *ngFor="let item of dates" (click)="click(item)"
          [class.minDate]="item?.getTime() < minDate?.getTime()" [class.active]="isCurrent(item)">
          <div class="weekBage" *ngIf="getPopulateData(item)">{{getPopulateData(item)}}</div>
          <div class="day">{{item | time: "DD" }}</div>
          <div class="month">{{item | time: "ddd"}}</div>
        </div>
        <button mat-icon-button (click)="showNextSeven()">
          <mat-icon class="subtle">chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <p *ngSwitchDefault>
    {{view}} NOT SUPPORTED
  </p>
</ng-container>