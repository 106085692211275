import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'oa-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.css']
})
export class NoDataFoundComponent implements OnInit {

  @Input()
  value: any;

  @Input()
  code: string;

  @Input()
  message = 'No Data Found';

  @Input()
  class: string;

  @Input()
  icon: string;

  items: any[] = [{
    code: 'ndf',
    message: 'No Data Found',
    icon: 'error-no-data'
  }, {
    code: 'all-done',
    icon: 'enjoy',
    message: 'All done for the day'
  }, {
    code: 'plan',
    icon: 'error-no-data',
    message: 'What do you plan to do next'
  },
  {
    code: 'initiate',
    icon: 'error-no-data',
    message: 'Add some tasks'
  },
  {
    code: 'initiate',
    icon: 'error-no-data',
    message: 'Pick something from To Do'
  },
  {
    code: 'nothing-pending',
    icon: 'enjoy',
    message: 'You wrapped up everything'
  }
  ];

  constructor() { }

  ngOnInit() {

    if (!(this.code || this.value)) {
      return;
    }

    if (this.code || typeof this.value === 'string') {
      const code = this.code || this.value;
      let value = this.items.find(i => i.code === code);
      if (value) {
        this.value = value;
      }
    }

    if (!this.value) { return; }

    this.message = this.value.message || this.message;
    this.icon = this.value.icon || this.icon;
    this.class = this.value.class || this.class;
  }

}
