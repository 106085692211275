<ng-container [ngSwitch]="type">
  <a mat-button *ngSwitchCase="'link'" [href]="item.value">{{item.title}}</a>
  <mat-button-toggle-group *ngSwitchCase="'select'" class="inline">
    <mat-button-toggle *ngFor="let option of item.options" (click)="onSelect(option)" [value]="option.code"
      aria-label="{{option.label}}">
      <oa-icon [value]="option.icon" [title]="option.label"></oa-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <ng-container *ngSwitchCase="'toggle'">
    <mat-slide-toggle [(ngModel)]="item.value" (change)="onToggle()">{{item.title}}</mat-slide-toggle>
  </ng-container>

  <ng-container *ngSwitchCase="'icon'">
    <oa-icon [value]="item.icon" (click)="onClick()" [title]="item.title" [class]="class" [style]="style"></oa-icon>
  </ng-container>

  <ng-container *ngSwitchCase="'button'">
    <ng-container [ngSwitch]="view">
      <button mat-raised-button *ngSwitchCase="'raised'" [class]="class" [style]="style" (click)="onClick()"
        [disabled]="disabled">
        <oa-icon *ngIf="item.icon" [value]="item.icon"></oa-icon>
        {{item.title}}
      </button>
      <button mat-stroked-button *ngSwitchCase="'stroked'" [class]="class" [style]="style" (click)="onClick()"
        [disabled]="disabled">
        <oa-icon *ngIf="item.icon" [value]="item.icon"></oa-icon>
        {{item.title}}
      </button>
      <button mat-flat-button *ngSwitchCase="'flat'" [class]="class" [style]="style" (click)="onClick()"
        [disabled]="disabled">
        <oa-icon *ngIf="item.icon" [value]="item.icon"></oa-icon>
        {{item.title}}
      </button>
      <button mat-fab *ngSwitchCase="'fab'" [class]="class" [style]="style" (click)="onClick()" [disabled]="disabled">
        <oa-icon [value]="item.icon"></oa-icon>
      </button>
      <button mat-mini-fab *ngSwitchCase="'mini-fab'" [class]="class" [style]="style" (click)="onClick()"
        [disabled]="disabled">
        <oa-icon [value]="item.icon"></oa-icon>
      </button>
      <button mat-icon-button *ngSwitchCase="'icon'" [class]="class" [style]="style" (click)="onClick()"
        [disabled]="disabled">
        <oa-icon [value]="item.icon"></oa-icon>
      </button>
      <button mat-button *ngSwitchDefault [class]="class" [style]="style" [style]="style" (click)="onClick()"
        [disabled]="disabled">
        <oa-icon *ngIf="item.icon" [value]="item.icon"></oa-icon>
        {{item.title}}
      </button>
    </ng-container>
  </ng-container>

  <button mat-button *ngSwitchCase="'icon-button'" (click)="onClick()" [disabled]="disabled"
    matTooltip="{{item.title}}">
    <oa-icon [value]="item.icon" [title]="item.title"></oa-icon>
  </button>
  <ng-container *ngSwitchCase="'more'">
    <div [matMenuTriggerFor]="moreMenu">
      <oa-icon [value]="item.icon" [title]="item.title"></oa-icon>
    </div>
    <mat-menu #moreMenu="matMenu">
      <button *ngFor="let item of menu" mat-menu-item (click)="item.event()">{{item.title}}</button>
    </mat-menu>
  </ng-container>

  <div *ngSwitchCase="'more-icons'" class="content" (clickOutside)="showList=false">
    <oa-icon [value]="item.icon" [title]="item.title" (click)="showList=!showList"></oa-icon>
    <div *ngIf="showList && menu.length" class="modes">
      <oa-icon class="m-10" *ngFor="let a of menu" (click)="a.event()" [value]="a.icon" [title]="a.title"
        [class]="a?.style"></oa-icon>
    </div>
  </div>
</ng-container>
