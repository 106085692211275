<div class="main" [class.hidden]="!isCurrent">
  <processing-indicator *ngIf="!task||task.isProcessing"></processing-indicator>
  <ng-container *ngIf="task">
    <div class="w-p scrollable">
      <div>
        <div class="action-bar">
          <span class="sub-text" *ngIf="task.parent && task.parent.code" (click)="gotoByCode(task.parent.code)">
            {{task.parent.code |uppercase}}</span>
          <span class="spacer"></span>
          <gateway-task-states [task]="task" view="stepper"></gateway-task-states>
          <span class="spacer"></span>
          <gateway-task-actions [task]="task" [view]="'action'" [options]="parentOptions"
            (updated)="onStatusUpdate($event)" #workflow>
          </gateway-task-actions>
        </div>
        <gateway-task-details #detail [properties]="task">
        </gateway-task-details>
        <div class="section" *ngIf="task?.workflow?.children?.length">
          <div class="header">
            <label>Subtasks</label>
            <span class="spacer"></span>
            <ng-container
              *ngTemplateOutlet="bulkActionsTemplate;context: { listType:'children', count:task.children.length}">
            </ng-container>
          </div>
          <gateway-task-new [project]="task.project" [parent]="task" [assignee]="task.assignee" view="list-item"
            (created)="onSubtaskCreate($event)" [placeholder]="'New Task'">
          </gateway-task-new>
          <gateway-task-list view="list" [parent]="task" [includeDiscarded]="false" [includeClosed]="true"
            [includeDraft]="true" [paging]="{limit: 100}" [columns]="subTaskColumns" [detailsTemplate]="detailsTemplate"
            (selected)="gotoByCode($event.code)">
          </gateway-task-list>
        </div>

        <div class="section">
          <div class="header">
            <label>Related To</label>
            <span class="spacer"></span>
            <ng-container
              *ngTemplateOutlet="bulkActionsTemplate;context:{listType:'dependsOn', count:task.dependsOn.length}">
            </ng-container>
          </div>
          <div class="m-b-10">
            <gateway-task-picker [params]="{'project-id': task?.project?.id}" [options]="options"
              (changed)="onUpdate($event,false)"></gateway-task-picker>
          </div>
          <gateway-task-list view="list" [dependsOn]="task" [includeClosed]="true" [includeDraft]="true"
            [includeDiscarded]="false" [columns]="relatedTaskColumns" [detailsTemplate]="detailsTemplate"
            (selected)="gotoByCode($event.code)" #dependsOnList>
          </gateway-task-list>
        </div>

        <div class="section">
          <label>Attachments</label>
          <drive-file-list *ngIf="folder && entity" view="grid" [entity]="entity" [visibility]="1" [folder]="folder"
            (selected)="onFileSelect($event)">
          </drive-file-list>
        </div>
        <div class="section">
          <label>Activity</label>
          <div class="tabs horizontal">
            <div [ngClass]="{active: tab === 'comments'}" (click)="tab = 'comments'">
              <i class="md notes"></i>
              <div>Comments</div>
            </div>
            <div [ngClass]="{active: tab === 'time-logs'}" (click)="tab = 'time-logs'">
              <i class="md section-business"></i>
              <div>Time Logs</div>
            </div>
            <div [ngClass]="{active: tab === 'history'}" (click)="tab = 'history'">
              <i class="md activities"></i>
              <div>History</div>
            </div>
          </div>

          <div class="body" [ngSwitch]="tab">
            <ng-container *ngSwitchCase="'comments'">
              <send-it-new-message [modes]="{chat: true}" [conversation]="conversation"></send-it-new-message>
              <send-it-conversation-detail [view]="'line'" [mode]="'notes'" [conversation]="conversation">
              </send-it-conversation-detail>
            </ng-container>

            <ng-container *ngSwitchCase="'time-logs'">
              <gateway-time-log-list [task]="task"></gateway-time-log-list>
            </ng-container>


            <ng-container *ngSwitchCase="'history'">
              <insight-journal-list [entity]="entity">
              </insight-journal-list>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="body highlighted">
          <gateway-task-details view="attributes" [properties]="task"></gateway-task-details>
        </div>
      </div>
    </div>
  </ng-container>

</div>

<ng-template #detailsTemplate let-task="task">
  <gateway-task-details view="row" [more]="more" [code]="task.code"></gateway-task-details>
</ng-template>

<ng-template #bulkActionsTemplate let-listType="listType" let-count="count">
  <mat-menu #menu="matMenu">
    <button *ngFor="let action of bulkActions" (click)="onBulkUpdate(action.code,listType)"
      mat-menu-item>{{action.label}}</button>
  </mat-menu>
  <mat-icon *ngIf="count > 0" class="subtle" [matMenuTriggerFor]="menu">more_vert</mat-icon>
</ng-template>

<router-outlet></router-outlet>
