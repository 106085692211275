import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnLoginGuard implements CanActivate {

  constructor(private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    // If the user is already logged in, redirect to the landing page
    if (user && user.meta && user.meta.user && user.meta.user.res) {
      this._router.navigate(['/landing']);
      return false;
    } else {
      return true; // Allow access to the login page if not logged in
    }
  }
}
