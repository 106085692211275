<processing-indicator *ngIf="isProcessing"></processing-indicator>

<div class="flex-row" [ngClass]="style?.container?.class" [ngStyle]="style?.container?.style">
  <div *ngFor="let column of items; let i = index " class="spacer">
    <div [ngStyle]="column.style?.header?.style || style?.header?.style" [class.clickable]="column.click"
      [ngClass]="column.style?.header?.class || style?.header?.class" (click)="onValueSelect(column, {})">
      <div *ngIf="column.icon" [ngStyle]="column.style?.icon?.style || style?.icon?.style"
        [ngClass]="column.style?.icon?.class || style?.icon?.class">
        <img [src]="column.icon">
      </div>
      <div [ngStyle]="column.style?.value?.style || style?.value?.style"
        [ngClass]="column.style?.value?.class || style?.value?.class">
        <div class="flex-row">
          <div class="target">{{column.target | value:'short'}}</div>
          <div class="spacer"></div>
          <div class="variance">{{getVariance(column)}}</div>
        </div>
        {{column.achieved | value:'short'}}
      </div>
      <div class="stacked" [ngStyle]="column.style?.title?.style || style?.title?.style"
        [ngClass]="column.style?.title?.class || style?.title?.class">
        <div class="spacer"></div>
        <div>{{column.label}}</div>
        <div class="spacer"></div>
      </div>
    </div>
  </div>
</div>