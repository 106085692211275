<ng-container [ngSwitch]="view">

  <div class="flex-row five" *ngSwitchCase="'grid'">
    <drive-file-detail *ngFor="let item of items" [readonly]="readonly" [fileUploadInputView]="fileUploadInputView"
      [properties]="item" [permissions]="permissions" (open)="select($event)" (removed)="init()"
      (afterUpdate)="onRefresh()" [view]="'card'">
    </drive-file-detail>

    <drive-file-uploader-zone *ngIf="!readonly" (click)="openDialog()">
      <div class="file-card stacked">
        <div class="content spacer stacked">
          <div class="spacer"></div>
          <i class="lg file-add"></i>
          <div class="spacer"></div>
        </div>

        <!-- <div class="controls-row summary">
          <h5>Add New File</h5>
        </div> -->
      </div>
    </drive-file-uploader-zone>
  </div>

  <ng-container *ngSwitchCase="'table'">
    <ng-container>
      <div class="list-file-area">
        <div class="table" *ngIf="items.length">
          <div class="header flex-row">
            <div class="index">Index</div>
            <div class="name">Name</div>
            <div class="uploader">Uploader</div>
            <div class="comman-field">Applicable From</div>
            <div class="comman-field">Expiry Date</div>
            <div class="comman-field">Status</div>
            <div class="actions centered-text">Actions</div>
          </div>
          <div class="data-row" *ngFor="let item of items;let i = index">
            <div class="row-container">
              <drive-file-detail [readonly]="readonly" [fileUploadInputView]="fileUploadInputView" [properties]="item"
                (afterUpdate)="onRefresh()" [permissions]="permissions" (open)="select($event)" (removed)="init()"
                [view]="'row-item'" [index]="i">
              </drive-file-detail>
            </div>
          </div>
          <div class="footer">
            <drive-file-uploader-zone *ngIf="!readonly" (click)="openDialog()">
              <a class="add-row">Add Document</a>
            </drive-file-uploader-zone>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'grouped-by-status'">
    <ng-container *ngIf="items.length">
      <div class="styled tomato text">Pending Documents</div>
      <ng-container *ngTemplateOutlet="groupedTemplate; context:{items:items,status:'draft'}"></ng-container>

      <div class="styled tangerine text">Rework Documents</div>
      <ng-container *ngTemplateOutlet="groupedTemplate; context:{items:items,status:'canceled'}"></ng-container>

      <div class="styled basil text">Uploaded Documents</div>
      <ng-container *ngTemplateOutlet="groupedTemplate; context:{items:items,status:'active'}"></ng-container>

      <div class="styled active-step text">Pending Reviews</div>
      <ng-container *ngTemplateOutlet="groupedTemplate; context:{items:items,status:'submitted'}"></ng-container>
    </ng-container>
    <div class="footer">
      <drive-file-uploader-zone *ngIf="!readonly" (click)="openDialog()">
        <a class="add-row">Add Document</a>
      </drive-file-uploader-zone>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'shortList'">
    <ng-container>
      <div class="table" *ngIf="items.length">
        <div class="header flex-row three">
          <div>Index</div>
          <div>Name</div>
          <div>Status</div>
        </div>
        <div class="data-row flex-row three" *ngFor="let item of items;let i = index">
          <div>{{i+1}}</div>
          <div>{{item.name}}</div>
          <div>{{item.status | uppercase}}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngSwitchCase="'list'">
    <ng-container *ngFor="let file of items">
      <div class="fileName cursorPointer flex-9-1">
        <div (click)="select(file)">{{file.name}}</div>
        <div class="closeIconDiv">
          <mat-icon *ngIf="auth.hasPermission(permissions.remove)" (click)="onRemove(file)" matTooltip="Remove">
            remove_circle_outline</mat-icon>
        </div>
      </div>
    </ng-container>
    <div class="noData" *ngIf="!items.length && !auth.hasPermission(permissions.create)"> No Data Found </div>
    <div class="addMore cursorPointer" *ngIf="auth.hasPermission(permissions.create)" (click)="openDialog()">+ Add More
    </div>
  </div>
  <div *ngSwitchCase="'entity-type-list'">
    <!-- <ng-container *ngFor="let entityType of entityTypeGroup | keyvalue">

      <div class="section mt-3">
        <div class=" header">
          <label class="px-2">{{entityType.key | uppercase }}</label>
        </div>
        <div class="flex-row five">
          <drive-file-detail *ngFor="let item of entityType.value" [readonly]="readonly"
            [fileUploadInputView]="fileUploadInputView" [properties]="item" [permissions]="permissions"
            (open)="select($event)" (removed)="init()" (afterUpdate)="onRefresh()" [view]="'card'">
          </drive-file-detail>
          <drive-file-uploader-zone *ngIf="!readonly" (click)="openDialog()">
            <div class="file-card stacked">
              <div class="content spacer stacked">
                <div class="spacer"></div>
                <i class="lg file-add"></i>
                <div class="spacer"></div>
              </div>
            </div>
          </drive-file-uploader-zone>
        </div>
      </div>

    </ng-container> -->
  </div>

  <div class="flex-row five" *ngSwitchCase="'attachment'">
    <drive-file-detail class=" my-1" *ngFor="let item of items" [readonly]="readonly"
      [fileUploadInputView]="fileUploadInputView" [properties]="item" [permissions]="permissions"
      (open)="select($event)" (removed)="init()" (afterUpdate)="onRefresh()" [view]="'attachment'">
    </drive-file-detail>
    <div class=" my-1" *ngIf="!readonly" (click)="openDialog()">
      <div class="justify-content-center oa-input pointer">
        <span class="spacer"></span>
        <mat-icon class="pointer mt-1">add_circle_outline</mat-icon>
        <!-- <div class="controls-row summary">
          <h5>Add New File</h5>
        </div> -->
      </div>
    </div>
  </div>

  <div class="flex-row five" *ngSwitchCase="'file-input'">
    <drive-file-detail *ngFor="let item of items" [readonly]="readonly" [fileUploadInputView]="fileUploadInputView"
      [properties]="item" [permissions]="permissions" (open)="select($event)" (removed)="init()"
      (afterUpdate)="onRefresh()" [view]="'file-input'">
    </drive-file-detail>
  </div>

  <ng-template #groupedTemplate let-items="items" let-status="status">
    <div class="table" *ngIf="items.length">
      <ng-container [ngSwitch]="status">
        <div class="header flex-row" *ngSwitchCase="'draft'">
          <div class="table-name">Name</div>
          <div class="table-description">Description</div>
          <div class="table-type">Type</div>
          <div class="table-action">Upload</div>
        </div>
        <div class="header flex-row" *ngSwitchCase="'canceled'">
          <div class="table-name">Name</div>
          <div class="table-description">Description</div>
          <div class="table-type">Type</div>
          <div class="table-action">Upload</div>
        </div>
        <div class="header flex-row" *ngSwitchCase="status === 'submitted'||status === 'active' ? status : ''">
          <div class="table-name">Name</div>
          <div class="table-description">Uploaded By</div>
          <div class="table-type">Type</div>
          <div class="table-action">View</div>
        </div>
      </ng-container>

      <ng-container *ngFor="let item of items;let i = index">
        <div class="data-row" *ngIf="item.status===status">
          <div class="row-container">
            <drive-file-detail [readonly]="readonly" [fileUploadInputView]="fileUploadInputView" [properties]="item"
              (afterUpdate)="onRefresh()" [permissions]="permissions" (open)="select($event)" (removed)="init()"
              [view]="'grouped-row-item'" [index]="i">
            </drive-file-detail>

          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>


  <!-- <div *ngIf="view === 'horizontal'">
    <ng-container *ngFor="let item of items; let i = index" [ngSwitch]="true">
      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 img-card"
        *ngSwitchCase="item.type === 'image/jpeg' || item.type === 'image/png' || item.type === 'image/jpg'">
        <img [src]="item.url" class="img">
        <a>
          <span class="notify-badge"><i class="close" (click)="remove(item.id)" matTooltip="Remove"
              width=" 60%"></i></span>
        </a>
      </div>
      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 video-card" *ngSwitchCase="item.type === 'video/mp4'">
        <video width="220" height="140" controls>
          <source [src]="item.url" type="video/mp4" class="video">
        </video>
        <a>
          <span class="notify-badge"><i class="close" (click)="remove(item.id)" matTooltip="Remove"
              width=" 60%"></i></span>
        </a>
      </div>
    </ng-container>
  </div> -->


  <!-- <div class="row gridViewRow" *ngIf="view === 'grid'">
    <div class="col-lg-2 col-md-2 textWrap" *ngFor="let item of items">
      <div>
        <a [href]="item.url" target="_blank">
          <i class="{{getFileIcon(item.mimeType)}}">
            <img *ngIf="!readonly" src="../../../assets/icons/folderImgDelete.svg" class="folderListDeleteIcon"
              (click)="remove(item.id)" matTooltip="Remove">
          </i>
        </a>
      </div>
      <a [href]="item.url" target="_blank">{{item.name}}</a>
      <p *ngIf="item.size">{{item.size}}</p>
    </div>
    <div *ngIf="!readonly" class="col-lg-2 col-md-2 upload-cardGrid" (click)="openDialog()">
      <div class="row">
        <i class="file-add lg customMargin"></i>
      </div>
      <div class="row">
        <label class="uploadText customMargin">Add New File</label>
      </div>
    </div>
  </div>


  <div *ngIf="view === 'recentFile'">
    <div class="row">
      <a [href]="item.url" target="_blank" class="col-lg-3" *ngFor="let item of items">
        <i class="{{getFileIcon(item.mimeType)}}"></i>
        <span class="nameOfItem">
          <a [href]="item.url" target="_blank">{{item.name}}</a>
        </span>
      </a>
    </div>
  </div>


  <div *ngIf="view === 'folderList'">
    <div class="row folderList">
      <div class="col-lg-8">

        <table class="table">
          <th>Names</th>
          <th>Size</th>
          <th>Date of Upload</th>

        </table>
        <div class="row" *ngFor="let item of items">
          <a [href]="item.url" target="_blank" class="col-5">
            <i class="si {{getFileIcon(item.mimeType)}}"></i>
            <span class="nameOfItem">
              <a [href]="item.url" target="_blank">{{item.name}}</a>
            </span>
          </a>
          <div class="col-2">
            {{getFileSize(item)}}
          </div>
          <div class="col-4">{{item.timeStamp | date: 'MM/dd/y'}}</div>
          <div *ngIf="!readonly" class="col-1">
            <button mat-mini-fab (click)="remove(item.id)" matTooltip="Remove"><img
                src="../../../../assets/icons/deleteList.svg" style="padding-bottom: 3px"></button>
          </div>
        </div>
      </div>
      <div *ngIf="!readonly" class="col-lg-4">
        <div class="upload-card-background">
          <div class="upload-card" (click)="openDialog()">
            <div class="row">
              <i class="file-add lg customMargin"></i>
            </div>
            <div class="row">
              <label class="uploadText customMargin">Add New File</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->



  <!--
  <div *ngIf="view === 'gallery'">
    <div class="row img-div">
      <ng-container *ngFor="let item of items">
        <div class="col-3 imgCard" (click)="showImgInDialog(item)">
          <span *ngIf="readonly">
            <img src="../../../assets/icons/galleryMinus.svg" class="galleryMinus-icon" (click)="remove(item.id)"
              matTooltip="Remove"></span>
          <div class="background"
            *ngIf="(item?.mimeType === 'image/png' || 'image/jpeg' || 'image/jpg') && (item.mimeType !== 'video/mp4')"
            [style.backgroundImage]="'url(' + item?.url + ')'">
          </div>
          <div class="background" *ngIf="item.mimeType === 'video/mp4'">
            <video class="background-video">
              <source class="video" type="video/mp4" [src]="item?.url">
            </video>
          </div>
        </div>
      </ng-container>
      <div *ngIf="addNewFile && !items.length" class="col-3 adjustUploadPoint">
        <drive-file-uploader-zone [types]="['image/png','image/jpeg','image/jpg','video/mp4']" [entity]="entity"
          [view]="'gallery'"> </drive-file-uploader-zone>
      </div>
      <div *ngIf="addNewFile && items.length" class="col-3 uploadPoint">
        <drive-file-uploader-zone [types]="['image/png','image/jpeg','image/jpg','video/mp4']" [entity]="entity"
          [view]="'gallery'"> </drive-file-uploader-zone>
      </div>
    </div>
  </div>


  <div *ngIf="view === 'galleryCertificate'">
    <div class="row certificates-div">
      <ng-container *ngFor="let item of items">
        <div class="col-3 certificatesCard" (click)="showImgInDialog(item)">
          <span *ngIf="readonly"><img src="../../../assets/icons/galleryMinus.svg" class="galleryMinus-icon"
              (click)="remove(item.id)" matTooltip="Remove"></span>
          <div class="certificates" [style.backgroundImage]="'url(' + item?.url + ')'">
          </div>
        </div>
      </ng-container>
      <div *ngIf="addNewFile" class="col-3 uploadPointTwo">
        <drive-file-uploader-zone [types]="['image/png','image/jpeg','image/jpg']" [entity]="entity" [view]="'gallery'">
        </drive-file-uploader-zone>
      </div>
    </div>
  </div>

  <div class="row main-card" *ngIf="view === 'carousel'">
    <div *ngIf="selectedFile">
      <div class="row background-img"
        *ngIf="(selectedFile.mimeType === 'image/png' || 'image/jpeg' || 'image/jpg') && (selectedFile.mimeType !== 'video/mp4')"
        [style.backgroundImage]="'url(' + selectedFile?.url + ')'">
        <button mat-icon-button class="background-icon">
          <mat-icon>favorite</mat-icon>
        </button>
      </div>
      <div class="row background-img" *ngIf="selectedFile.mimeType === 'video/mp4'">
        <video controls class="background-video">
          <source class="video" type="video/mp4" [src]="selectedFile?.url">
        </video>

      </div>
    </div>
    <div class="row slider">
      <div class="col-lg-9 col-md-9 col-sm-9">
        <div class="dots">
          <a (click)="onDotClick(i)" *ngFor="let item of items;let i = index">
            <mat-icon [ngClass]="{active: item.id === selectedFile.id}">
              fiber_manual_record
            </mat-icon>
          </a>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3" *ngIf="items && items.length > 1">
        <button mat-icon-button (click)="previous()">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button mat-icon-button (click)="next()">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>
  </div> -->


</ng-container>
