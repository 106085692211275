<processing-indicator *ngIf="isProcessing"></processing-indicator>
<ng-container *ngIf="properties">
  <div mat-dialog-header>
    <h4 mat-dialog-title>{{properties.id? properties.name : 'New Sprint'}}</h4>
  </div>

  <div mat-dialog-content>

    <div class="flex-row one">
      <div>
        <label for="name">Name</label>
        <input class="oa-input" name="name" [(ngModel)]="properties.name" cdkFocusInitial required>
      </div>
      <div>
        <label for="description">Description</label>
        <oa-input [value]="properties.description ? properties.description:''"
          (changed)="properties.description = $event"></oa-input>
      </div>
    </div>


    <div class="flex-row two">
      <div>
        <label for="startDate">Start Date</label>
        <oa-date-picker [value]="properties.plan.start" (change)="onStartDateChange($event)"></oa-date-picker>
      </div>
      <div>
        <label for="dueDate">Due Date</label>
        <oa-date-picker [value]="properties.plan.finish" (change)="onFinishDateChange($event)"></oa-date-picker>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="footer">
    <button *ngIf="properties.status === 'new'" mat-button (click)="onDiscard()">Discard</button>
    <button *ngIf="properties.status === 'new'" mat-raised-button color="primary" (click)="onStart()">Start</button>
    <button *ngIf="properties.status === 'active'" mat-raised-button color="primary"
      (click)="onComplete()">Complete</button>
    <button *ngIf="properties.status === 'closed'" mat-button (click)="onStart()">Reopen</button>

    <span class="spacer"></span>
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button (click)="onSave()" color="primary">Save</button>
  </div>
</ng-container>