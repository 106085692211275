export const environment = {
  // enviroment = tenant
  production: true,
  name: 'dev',
  env: 'development',
  debug: {
    host: 'localhost:4207',
  },

  code: 'altos-dev',
  title: 'Altos',
  host: 'dev-qmepanel-ui.azurewebsites.net', // this is only for local debugging
  app: {
    name: 'Altos',
    code: 'altos-dev',
    version: 1,
  },
  apiUrl: {
    api: 'https://dev-qmepanel-api.azurewebsites.net/api',
  },
  // source: 'https://dev.openage.in/directory/api/tenants', // dev
  // source: '/assets/data/tenants/{{code}}.json',P
  // source: 'https://api.openage.in/system/v1/api/applications/host:{{host}}', // prod
  source: '/assets/data/tenants/source.json',
  organization: { code: null },
  captcha: {
    provider: 'google',
    type: 'recaptcha',
    key: '6LelHKwaAAAAAAkcYHXGeHd6UYrcnektUrkLfLSY',
  },
  theme: {
    code: 'default',
    style: '/assets/themes/checklist/style.css',
    type: 'light',
  },
  links: {
    signup: '/landing/signup',
    login: '/landing/login',
    help: '/support/help',
    console: 'https://console.actionitems.in',
  },
  session: {
    timeout: 0,
    cache: {
      duration: 10,
      storage: 'local',
    },
  },
  errors: [
    {
      key: 'ID_INVALID',
      message: 'Not a valid input',
    },
    {
      key: 'HIDDEN',
      message: '',
    },
  ],
  loginTypes: ['email'],

  // if services section exists then it would be used
  services: [],
  // if navs section exists then it would be used
  navs: [
    // {
    //   code: 'landing',
    //   src: '/assets/data/nav/landing/nav.json'
    // },
    // {
    //   code: 'home',
    //   src: '/assets/data/nav/home/nav.json'
    // },
    {
      code: 'upload',
      src: '/assets/data/nav/upload-data/nav.json'
    },
    // {
    //   code: 'compute',
    //   src: '/assets/data/nav/compute-data/nav.json'
    // },
    {
      code: 'users',
      src: '/assets/data/nav/user/nav.json'
    },
    // {
    //   code: 'provider',
    //   src: '/assets/data/nav/provider/nav.json'
    // },
    // {
    //   code: 'panel',
    //   src: '/assets/data/nav/panel/nav.json'
    // },
    // {
    //   code: 'zip-code',
    //   src: '/assets/data/nav/zip-code/nav.json'
    // },
    // {
    //   code: 'providers-report',
    //   src: '/assets/data/nav/provider-report/nav.json'
    // },
    // {
    //   code: 'panel-report',
    //   src: '/assets/data/nav/panel-report/nav.json'
    // },
    {
      code: 'display-data',
      src: '/assets/data/nav/display-data/nav.json'
    },
    {
      code: 'what-if',
      src: '/assets/data/nav/what-if/nav.json',
    },

    // {
    //   code: 'settings',
    //   src: '/assets/data/nav/settings/nav.json'
    // }
  ],

  styles: '/assets/themes/checklist/style.css',
};
