import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { InactivityService } from 'src/app/services/inactivity.service';


@Injectable({
  providedIn: 'root',
})
export class InactivityGuard implements CanActivate {
  constructor(private inactivityService: InactivityService) {}

  canActivate(): boolean {
    // Ensure the inactivity service is running
    this.inactivityService.resetTimer() // Reset timer on route change
    return true;
  }
}