<processing-indicator *ngIf="isProcessing"></processing-indicator>
<div *ngIf="selectedFile && (view === 'banner')">
  <div class="row bannerBackground" [style.backgroundImage]="'url(' + selectedFile?.url + ')'">
    <button class="leftBtn" mat-icon-button (click)="previous()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div class="dots">
      <a (click)="onDotClick(i)" *ngFor="let item of properties.files;let i = index">
        <mat-icon [ngClass]="{active: item.id === selectedFile.id}">
          fiber_manual_record
        </mat-icon>
      </a>
    </div>
    <div class="banner-content">
      <div class="bannerTitle">{{selectedFile.name}}</div>
      <div class="bannerDesc"> {{selectedFile.description}}</div>
    </div>

    <button class="rightBtn" mat-icon-button (click)="next()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="properties && (view === 'grid')">
  <p class="div-title">{{properties.name}}</p>
  <p class="div-desc">{{properties.description}}</p>
  <div *ngIf="view === 'grid'" class="flex-row three folders-list">
    <div *ngFor="let file of properties.files ">
      <div class="banner-mainDiv">
        <div class="iconDiv" [style.backgroundImage]="'url(' + file?.url + ')'"></div>
        <div class="file-name">{{file.name}}</div>
        <div class="file-desc"> {{file.description}}</div>
      </div>
    </div>
  </div>
</div>
