<processing-indicator *ngIf="isProcessing"></processing-indicator>

<ng-container [ngSwitch]="view">

  <ng-container *ngSwitchCase="'table'">
    <table *ngIf="items.length; else noData" mat-table [dataSource]="items">

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Code</th>
        <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
          {{item.code|uppercase}}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
          {{item.name|uppercase}}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
          {{item.description}}
        </td>
      </ng-container>

      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef>Start</th>
        <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
          {{item.plan.start?(item.plan.start | date: 'd MMM, y'):'-- --'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef>End</th>
        <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
          {{item.plan.finish?(item.plan.finish | date: 'd MMM, y'):'-- --'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
          {{item.status | uppercase}}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
          <div class="footer button">
            <button *ngIf="item.status === 'new'" mat-raised-button color="primary"
              (click)="onStatusChange(item,'in-progress')">In-Progress</button>
            <button *ngIf="item.status === 'in-progress'" mat-raised-button color="primary"
              (click)="openDialog(item)">Close</button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true">
      </tr>
      <tr mat-row *matRowDef="let row; columns: columns;">
      </tr>
    </table>
  </ng-container>

</ng-container>

<ng-template #noData>
  <oa-no-data-found [message]="'No Data Found!'"></oa-no-data-found>
</ng-template>
