<processing-indicator *ngIf="isProcessing"></processing-indicator>

<div class="flex-7-3" *ngIf="properties">
    <div>
        <ng-container *ngIf="properties.code">
            <div class="bordered">
                <div class="controls-row">
                    <h5>Release Attachments</h5>
                    <div class="spacer"></div>
                    <mat-icon (click)="releaseTasklist.onRefresh()" [matTooltip]="'Refresh'" class="subtle">refresh
                    </mat-icon>
                </div>
                <div>
                    <label>Subject</label>
                    <div class="oa-input">
                        <input placeholder="Search by subject" [(ngModel)]="releaseSubject">
                    </div>
                </div>
                <br>
                <gateway-task-list #releaseTasklist [bulkDetachBtn]="(properties.currentStatus &&
                (properties.currentStatus.code === 'new' || properties.currentStatus.code === 'rollback')) ? true: false"
                    [view]="'release'" [showCheckBox]="(properties.currentStatus &&
                (properties.currentStatus.code === 'new' || properties.currentStatus.code === 'rollback')) ? true: false"
                    (refresh)="releaseTasklist.onRefresh(); tasklist.onRefresh()" [text]="releaseSubject"
                    [release]="properties" [includeClosed]="true" [type]="'story,defect'" [footer]="'statusbar'"
                    [detailsTemplate]="detailsTemplate">
                </gateway-task-list>
            </div>

            <div class="bordered" *ngIf="properties.currentStatus &&
             (properties.currentStatus.code === 'new' || properties.currentStatus.code === 'rollback')">
                <div class="controls-row">
                    <h5>List</h5>
                    <div class="spacer"></div>
                    <mat-icon (click)="tasklist.onRefresh()" [matTooltip]="'Refresh'" class="subtle">refresh</mat-icon>
                </div>
                <div class="flex-row two">
                    <!-- <div>
                        <label>Code</label>
                        <div class="oa-input">
                            <input placeholder="Search by code" [(ngModel)]="listCode">
                        </div>
                    </div> -->
                    <div>
                        <label>Subject</label>
                        <div class="oa-input">
                            <input placeholder="Search by subject" [(ngModel)]="subject">
                        </div>
                    </div>
                    <div>
                        <label>Type</label>
                        <mat-select [(ngModel)]="listType">
                            <mat-option [value]="'story,defect'">All</mat-option>
                            <mat-option [value]="'story'">Story</mat-option>
                            <mat-option [value]="'defect'">Defect</mat-option>
                        </mat-select>
                    </div>
                </div>

                <br>
                <gateway-task-list #tasklist [view]="'release'" [bulkAttachBtn]="(properties.currentStatus &&
                (properties.currentStatus.code === 'new' || properties.currentStatus.code === 'rollback')) ? true: false"
                    (refresh)="releaseTasklist.onRefresh(); tasklist.onRefresh()" [type]="listType" [text]="subject"
                    [release]="null" [addRelease]="properties" [isClosed]="true" [isCancelled]="false" [footer]="'statusbar'"
                    [detailsTemplate]="detailsTemplate">
                </gateway-task-list>
            </div>
        </ng-container>

        <ng-container *ngIf="!properties.code">
            <div class="iconDiv">
                <i class="oa xx-lg release"></i>
            </div>
        </ng-container>
    </div>
    <div>
        <div class="bordered">
            <div class="controls-row" *ngIf="properties.code">
                <h5 class="capitalize">{{properties.code}}</h5>
                <span class="spacer"></span>
                <button mat-raised-button class="primary" *ngIf="properties.currentStatus &&
                (properties.currentStatus.code === 'new' || properties.currentStatus.code === 'rollback')"
                    (click)="save()">save</button>
            </div>
            <div *ngIf="!properties.code">
                <label>Type</label>
                <mat-select [(ngModel)]="properties.type" (ngModelChange)="setWorkflow()">
                    <mat-option value="major">Major</mat-option>
                    <mat-option value="minor">Minor</mat-option>
                    <mat-option value="patch">Patch</mat-option>
                </mat-select>
            </div>
            <div>
                <label>Name</label>
                <div class="oa-input">
                    <input [(ngModel)]="properties.name">
                </div>
            </div>
            <div>
                <label>Plan date</label>
                <oa-date-picker [value]="properties?.plan?.finish" (change)="setPlanDate($event)">
                </oa-date-picker>
            </div>
            <div>
                <label>Description</label>
                <div class="oa-textarea">
                    <textarea [id]="'remark'" [(ngModel)]="properties.description"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #detailsTemplate let-task="task">
    <gateway-task-details view="row" [code]="task.code"></gateway-task-details>
</ng-template>