<div class="popup">
  <div class="backdrop"></div>
  <div class="outer">
    <div class="content text-center">
      <div class="image-container">
        <processing-indicator *ngIf="isProcessing" [inline]="true"></processing-indicator>
        <img id="image" src="" alt="image">
      </div>
      <div *ngIf="isEdit" class="flex-row three">
        <div *ngIf="options.crop">
          <button type="button" mat-flat-button (click)="cropper.setDragMode('move')" title="Move">
            <i class="fa fa-arrows"></i>
          </button>
          <button type="button" mat-flat-button (click)="cropper.setDragMode('crop')" title="Crop">
            <i class="fa fa-crop"></i>
          </button>
        </div>
        <div *ngIf="options.zoom">
          <button type="button" mat-flat-button (click)="cropper.zoom(0.1)" title="Zoom In">
            <div class="fa fa-search-plus"></div>
          </button>
          <button type="button" mat-flat-button (click)="cropper.zoom(-0.1)" title="Zoom Out">
            <div class="fa fa-search-minus"></div>
          </button>
        </div>
        <div *ngIf="options.move">
          <button type="button" mat-flat-button (click)="cropper.move(-10,0)" title="Move Left">
            <span class="fa fa-arrow-left"></span>
          </button>
          <button type="button" mat-flat-button (click)="cropper.move(10,0)" title="Move Right">
            <span class="fa fa-arrow-right"></span>
          </button>
          <button type="button" mat-flat-button (click)="cropper.move(0,-10)" title="Move Up">
            <span class="fa fa-arrow-up"></span>
          </button>
          <button type="button" mat-flat-button (click)="cropper.move(0,10)" title="Move Down">
            <span class="fa fa-arrow-down"></span>
          </button>
        </div>
        <div *ngIf="options.rotate">
          <button type="button" mat-flat-button (click)="cropper.rotate(-45)" title="Rotate Left">
            <span class="fa fa-rotate-left"></span>
          </button>
          <button type="button" mat-flat-button (click)="cropper.rotate(45)" title="Rotate Right">
            <span class="fa fa-rotate-right"></span>
          </button>
        </div>

        <div *ngIf="options.flip">
          <button type="button" mat-flat-button (click)="cropper.scaleX(-1)" title="Flip Horizontal">
            <span class="fa fa-arrows-h"></span>
          </button>
          <button type="button" mat-flat-button (click)="cropper.scaleY(-1)" title="Flip Vertical">
            <span class="fa fa-arrows-v"></span>
          </button>
        </div>
        <div *ngIf="options.ratio">
          <button type="button" mat-flat-button (click)="cropper.crop()" title="Enable Crop">
            <span class="fa fa-check"></span>
          </button>
          <button type="button" mat-flat-button (click)="cropper.clear()" title="Clear Crop">
            <span class="fa fa-remove"></span>
          </button>
        </div>
        <!-- <div *ngIf="ratioList"  *ngIf="options.ratio">
        <button *ngFor="let ratio of ratioList" type="button" mat-flat-button (click)="selectRatio(ratio)"
          title="{{ratio?.name}}">
          {{ratio?.name}}
        </button>
      </div> -->
        <div *ngIf="options.ratio">
          <button type="button" mat-flat-button (click)="cropper.setAspectRatio(16/9)" title="16/9">
            16:9
          </button>
          <button type="button" mat-flat-button (click)="cropper.setAspectRatio(4/3)" title="4/3">
            4:3
          </button>
          <button type="button" mat-flat-button (click)="cropper.setAspectRatio(1/1)" title="4/3">
            1:1
          </button>
          <button type="button" mat-flat-button (click)="cropper.setAspectRatio(2/3)" title="2/3">
            2:3
          </button>
          <button type="button" mat-flat-button (click)="cropper.setAspectRatio(0)" title="NaN">
            Free
          </button>
        </div>
        <div *ngIf="options.reset">
          <button type="button" mat-flat-button (click)="cropper.reset()" title="Reset">
            <span class="fa fa-refresh"></span>
          </button>
          <button *ngIf="!isEditMandatory" type="button" mat-flat-button (click)="toggleEdit(false)" title="Destroy">
            <span class="fa fa-power-off"></span>
          </button>
        </div>
      </div>
      <div class="footer">
        <button mat-raised-button *ngIf="cancelable" [disabled]="isProcessing"
          (click)="cancelClicked()">{{cancelLabel}}</button>
        <button mat-raised-button *ngIf="!isEdit" (click)="toggleEdit(true)" title="Edit Image">Edit</button>
        <button mat-raised-button (click)="okClicked()" color="primary" [disabled]="isProcessing">
          {{okLabel}}
        </button>
      </div>
    </div>
  </div>
</div>