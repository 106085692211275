import { Pic } from 'src/app/lib/oa/core/models/pic.model';
import { User } from 'src/app/lib/oa/directory/models/user.model';
import { ModelBase } from '../../core/models/model-base.model';
import { Link } from '../../core/structures';
import { Service } from './service.model';

export class Tenant extends ModelBase {

  env: string;
  host: string;
  logo: Pic;
  joinUrl: string;
  homeUrl: string;
  owner: User;
  navs: Link[];
  services: Service[];
  level: string;
  styles: string;

  app: {
    name: string,
    code: string,
    version: number
  };

  theme: any;

  constructor(obj?: any) {
    super(obj);
    if (!obj) {
      return;
    }

    this.host = obj.host;
    this.logo = new Pic(obj.logo);
    this.joinUrl = obj.joinUrl;
    this.homeUrl = obj.homeUrl;
    this.level = obj.level;
    this.theme = obj.theme;

    if (obj.owner) {
      this.owner = new User(obj.owner);
    }

    if (obj.navs) {
      this.navs = obj.navs.map((n) => new Link(n));
    }

    if (obj.services) {
      this.services = obj.services.map((s) => new Service(s));
    }

    if (obj.styles) {
      this.styles = obj.styles;
    }

    if (obj.app) {
      this.app = {
        name: obj.app.name,
        code: obj.app.code,
        version: obj.app.version,
      };
    }
  }
}
