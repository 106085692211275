<processing-indicator *ngIf="isProcessing"></processing-indicator>
<ng-container *ngIf="items.length">
  <table mat-table class="mat-elevation-z8" [dataSource]="items" *ngIf="view === 'table'">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
        [class.processing]="item.isProcessing">{{item.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>Code</th>
      <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
        [class.processing]="item.isProcessing">
        {{item.code || '--'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
        [class.processing]="item.isProcessing">
        {{item.description || '--'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted" [class.processing]="item.isProcessing">
        <!-- <button style="margin-right: 5px;" mat-icon-button [disabled]="item.isProcessing" matTooltip="Edit"
          (click)="select(item)">
          <mat-icon>edit</mat-icon>
        </button> -->
        <button style="margin-right: 5px;" mat-icon-button [disabled]="item.isProcessing" matTooltip="Delete"
          (click)="onRemove(item)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['code', 'name', 'description', 'action']; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: ['code', 'name', 'description', 'action'];"></tr>
  </table>

  <app-paginator [pager]="pager" [total]="pager.total"></app-paginator>
</ng-container>