import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-logs',
  templateUrl: './time-logs.component.html',
  styleUrls: ['./time-logs.component.scss']
})
export class TimeLogsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
