<div *ngIf="properties">
  <div *ngIf="readonly">
    <div class="flex-row three">
      <div>
        <label>Name</label>
        <div>{{properties.name}}</div>
      </div>
      <!-- <div>
        <label>Entity Id</label>
        <div>{{properties.entity.id}}</div>
      </div>
      <div>
        <label>Entity Id</label>
        <div>{{properties.entity.id}}</div>
      </div> -->
    </div>

    <div>
      <label>Description</label>
      <div>{{properties.description}}</div>
    </div>
  </div>
</div>
<div *ngIf="!readonly">
  <div class="flex-row three">
    <div>
      <label>Name</label>
      <div class="oa-input">
        <input name="name" [(ngModel)]="properties.name" cdkFocusInitial required>
      </div>
    </div>
    <!-- <div>
      <label>Entity Id</label>
      <div class="oa-input">
        <input [(ngModel)]="properties.entity.id">
      </div>
    </div> -->
    <!-- <div>
      <label>Entity Type</label>
      <div class="oa-input">
        <input [(ngModel)]="properties.entity.type">
      </div>
    </div> -->
  </div>
  <div>
    <label>Description</label>
    <div class="oa-textarea">
      <textarea name="description" [(ngModel)]="properties.description"></textarea>
    </div>
  </div>
</div>