<footer id="myFooter">
  <div class="container">
    <ul>
      <li><a href="#">Company Information</a></li>
      <li><a href="#">Contact us</a></li>
      <li><a href="#">Reviews</a></li>
      <li><a href="#">Terms of service</a></li>
    </ul>
    <p class="footer-copyright">© 2022 Copyright Text</p>
  </div>
  <div class="footer-social">
    <a href="#" class="social-icons"><i class="fa fa-facebook"></i></a>
    <a href="#" class="social-icons"><i class="fa fa-google-plus"></i></a>
    <a href="#" class="social-icons"><i class="fa fa-twitter"></i></a>
  </div>
</footer>
