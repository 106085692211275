<ng-container>
  <oa-input label="Current Password" type="password" [(value)]="query.password" [required]="true"
    (errored)="error = $event" [options]="{format: {trim: true}}"></oa-input>

  <oa-input label="New Password" type="password" [(value)]="query.password" [required]="true" (errored)="error = $event"
    [validate]="validationService.isPasswordValid" [options]="{format: {trim: true}}"></oa-input>

  <oa-input label="Confirm Password" type="password" [(value)]="confirmPassword" [required]="true"
    (errored)="error = $event" [validate]="isConfirmPasswordValid" [options]="{format: {trim: true}}">
  </oa-input>

  <!-- <div class="controls-row" *ngIf="isInvalidPassword">
    <mat-hint class="error">Invalid password pattern</mat-hint>
    <mat-icon class="subtle password-info-icon"
      matTooltip="Minimum of 8 and maximum of 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character">
      info</mat-icon>
  </div> -->
  <div class="controls-row change-password-actions">
    <span class="spacer"></span>
    <button mat-raised-button color="primary" [disabled]="error" (click)="onSave()">Save</button>
  </div>
</ng-container>
