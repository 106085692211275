import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private timeoutId: any;
  private readonly TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes

  constructor(private router: Router, private ngZone: NgZone) {
    this.resetTimer();
    this.initializeListener();
    this.listenToStorageChanges();
  }

  private initializeListener() {
    // Listen to various user activities
    window.addEventListener('mousemove', () => this.resetTimer());
    window.addEventListener('keydown', () => this.resetTimer());
    window.addEventListener('click', () => this.resetTimer());
    window.addEventListener('scroll', () => this.resetTimer());
  }

  public resetTimer() {
    // Clear the previous timer
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.ngZone.runOutsideAngular(() => {
      this.timeoutId = setTimeout(() => this.logoutUser(), this.TIMEOUT_DURATION);
    });
  }

  private logoutUser() {
    // Clear user session or tokens here
    window.localStorage.clear()
    // Redirect to login page
    this.router.navigate(['/login']).then(() =>{
      window.location.reload();
    });
  }

  private listenToStorageChanges() {
    window.addEventListener('storage', (event) => {
      if (event.storageArea.length <= 2 && !event.storageArea.user) {
        this.ngZone.run(() => {
          this.logoutUser();
        });
      }
    });
  }
}
