import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoleService } from '../lib/oa/core/services';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private apiUrl: string;
  constructor(private http: HttpClient, private session: RoleService) {
    this.apiUrl = environment.apiUrl.api;
  }

  post(url, data, queryParams?, config?: any): any {
    const header = this.getCustomHeader(config);

    // Add query parameters to the URL if queryParams is provided
    if (queryParams) {
      let params = new HttpParams();
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          params = params.set(key, queryParams[key]);
        }
      }
      header['params'] = params;
    }

    return this.http.post(this.apiUrl + url, data, header);
  }

  put(url, data, queryParams?, config?: any): any {
    const header = this.getCustomHeader(config);

    // Add query parameters to the URL if queryParams is provided
    if (queryParams) {
      let params = new HttpParams();
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          params = params.set(key, queryParams[key]);
        }
      }
      url += '?' + params.toString(); // Append the query parameters to the URL
    }

    return this.http.put(this.apiUrl + url, data, header);
  }

  patch(url, data, config?: any): any {
    return this.http.patch(
      this.apiUrl + url,
      data,
      this.getCustomHeader(config)
    );
  }

  delete(url, data?, config?: any): any {
    return this.http.delete(
      this.apiUrl + url,
      this.getCustomHeader(config, data)
    );
  }

  deleteWithQuery(url, httpParams?: any, config?: any): any {
    for (let item in httpParams) {
      if (
        httpParams[item] === '' ||
        httpParams[item] === undefined ||
        httpParams[item] === null
      ) {
        delete httpParams[item];
      }
    }
    const header = this.getCustomHeader(config);
    if (httpParams) {
      header['params'] = httpParams;
    }
    return this.http.delete(this.apiUrl + url, header);
  }

  get(url, httpParams?: any, config?: any): any {
    for (let item in httpParams) {
      if (
        httpParams[item] === '' ||
        httpParams[item] === undefined ||
        httpParams[item] === null
      ) {
        delete httpParams[item];
        // delete httpParams["Match"];
      }
    }
    const header = this.getCustomHeader(config);
    if (httpParams) {
      header['params'] = httpParams;
    }
    return this.http.get(this.apiUrl + url, header);
  }

  getCustomHeader(config?, data?) {
    if (config && config.customHeader) {
      return {
        headers: {
          config: JSON.stringify(config || {}),
          ...config.customHeader,
        },
      };
    }
    return {
      headers: {
        config: JSON.stringify(config || {}),
      },
    };
  }

  deleteWithBody(url, body?: any, config?: any): any {
    for (let item in body) {
      if (
        body[item] === '' ||
        body[item] === undefined ||
        body[item] === null
      ) {
        delete body[item];
      }
    }
    const header = this.getCustomHeader(config);
    if (body) {
      header['body'] = body;
    }
    return this.http.delete(this.apiUrl + url, header);
  }
}
