<oa-autocomplete [value]="value && value.code? value : null " [options]="{
  label: placeholder,
  templates: {
    value: valueTemplate,
    placeholder: placeholderTemplate
  },
  search: {
    field: paramField || 'text'
  }
}" [api]="api" (changed)="onSelect($event)" [readonly]="readonly">
</oa-autocomplete>

<ng-template #valueTemplate let-item="value">
  <div class="value" [class.readonly]="readonly">
    {{item.name}}
  </div>
</ng-template>

<ng-template #placeholderTemplate>
  <div>Search</div>
</ng-template>