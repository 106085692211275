<oa-autocomplete [value]="value && value.code? value : null " [options]="{
  label: label,
  search: {
    items: items,
    field: searchField,
    params: params,
    limit: 20
  },
  templates: {
    value: valueTemplate
  }
}" [api]="api" (changed)="onSelect($event)" [readonly]="readonly">
</oa-autocomplete>

<ng-template #valueTemplate let-item="value">
  {{item.name}}
</ng-template>
