<div mat-dialog-title class="controls-row">
  <h4>{{title}}</h4>
  <span class="spacer"></span>
  <mat-icon class="m-r-10 pointer" (click)="onMinimize()">minimize</mat-icon>
  <button mat-icon-button mat-dialog-close class="close-icon" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>

  <div *ngIf="options.config && options.config.items" class="list">
    <label>{{options?.config?.title}} </label>
    <mat-select [(ngModel)]="options?.queryParams.format" maxlength="100" (selectionChange)="onChange($event)">
      <mat-option *ngFor="let item of options.config.items" [value]="item.format">{{item.name|uppercase}}</mat-option>
    </mat-select>
  </div>

  <shared-file-uploader [uploader]="uploader" [selectedMapper]="selectedMapper" [errorMessage]="errorMessage" [options]="options" #fileUploader>
  </shared-file-uploader>

  <processing-indicator *ngIf="isProcessing" [view]="'progress-bar'" [progressBarvalue]="task.progress" [inline]="true" [progressBarMode]="'determinate'"></processing-indicator>

</div>
<div mat-dialog-actions class="buttons controls-row">
  <span class="spacer"></span>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="disabled" (click)="onUpload()">Upload</button>
</div>