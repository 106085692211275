<processing-indicator *ngIf="isProcessing"></processing-indicator>

<div class="table table-responsive-sm" *ngIf="items.length; else noData">
  <div class="header">
    <div class="pic"></div>
    <div class="name">Name</div>
    <div class="contact">Contact</div>
    <div class="role">Role</div>
    <!-- <div class="supervisor">Supervisor</div> -->
    <div class="status">Status</div>
    <div class="actions"></div>
  </div>
  <ng-container *ngFor="let item of items" [class.disabled]="item.isProcessing">
    <ng-container *ngTemplateOutlet="item.isSelected ? editRow : detailRow; context: { item: item }"></ng-container>
  </ng-container>
</div>
<app-paginator [pager]="pager" [total]="pager.total"></app-paginator>


<ng-template #detailRow let-item="item">
  <div class="data-row">
    <div class="pic">
      <oa-avatar [value]="item.profile"></oa-avatar>
    </div>
    <div class="name">{{item.profile | name }}
      <div class="sub-text">{{item.code}}</div>
    </div>
    <div class="contact">{{item.email }}
      <br>{{item.phone }}
    </div>
    <div class="forle">
      {{item.designation?.name | titlecase}}
      <div class="sub-text">{{item.department?.name | titlecase}}</div>
    </div>
    <!-- <div class="supervisor">
      {{item.supervisor?.profile | name}}
      <br>
      <div class="sub-text">{{item.supervisor?.code}}</div>
    </div> -->
    <div class="status">{{item.status | titlecase}}</div>
    <div class="actions">
      <oa-action item="details" (click)="onSelect(item)"></oa-action>
    </div>
  </div>
</ng-template>

<ng-template #editRow let-item="item">
  <div class="data-row">
    <div class="col-code">{{item.code | uppercase}}</div>
    <div class="name">{{item.name}}</div>
    <div class="actions">
      <oa-action item="done" (click)="save(item)"></oa-action>
    </div>
  </div>
</ng-template>

<ng-template #noData>
  <oa-no-data-found message="No Employee Found"></oa-no-data-found>
</ng-template>
