<div class="row main-card">
  <div *ngIf="selectedFile">

    <div class="row background-img"
      *ngIf="(selectedFile.mimeType === 'image/png' || 'image/jpeg' || 'image/jpg') && (selectedFile.mimeType !== 'video/mp4')"
      [style.backgroundImage]="'url(' + selectedFile?.url + ')'">
      <button mat-icon-button class="background-icon">
        <mat-icon>favorite</mat-icon>
      </button>
    </div>
    <div class="row background-img" *ngIf="selectedFile.mimeType === 'video/mp4'">
      <video controls class="background-video">
        <source class="video" type="video/mp4" [src]="selectedFile?.url">
      </video>
      <!-- <button mat-icon-button class="background-icon">
        <mat-icon>favorite</mat-icon>
      </button> -->
    </div>
  </div>
  <div class="row slider">
    <div class="col-lg-9 col-md-9 col-sm-9">
      <div class="dots">
        <a (click)="onDotClick(i)" *ngFor="let item of files;let i = index">
          <mat-icon [ngClass]="{active: item.id === selectedFile.id}">
            fiber_manual_record
          </mat-icon>
        </a>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3" *ngIf="files && files.length > 1">
      <button mat-icon-button (click)="previous()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button mat-icon-button (click)="next()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>
</div>
