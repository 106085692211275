  <processing-indicator *ngIf="isProcessing"></processing-indicator>
  <ng-template [ngIf]="items.length" [ngIfElse]="noData">
    <table mat-table [dataSource]="items" *ngIf="view === 'table'">
      <ng-container matColumnDef="pic">
        <th mat-header-cell *matHeaderCellDef></th>
        <td (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
          [class.processing]="item.isProcessing">
          <div *ngIf="item.profile && item.profile.pic && item.profile.pic.url" class="avatar"
            [style.background-image]="'url(' + item.profile.pic.url + ')'">
          </div>
          <div *ngIf="!item.profile.pic.url" class="avatar default"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
          [class.processing]="item.isProcessing">
          <ng-container *ngIf="item.profile">{{item.profile.firstName | titlecase}} {{item.profile.lastName | titlecase}}
          </ng-container>
          <ng-container *ngIf="!item.profile || (!item.profile.firstName && !item.profile.lastName)">
            {{item.code}}</ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Code</th>
        <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
          [class.processing]="item.isProcessing">
          {{item.code || '--'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
          [class.processing]="item.isProcessing">
          {{item.type.name || '--'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
          [class.processing]="item.isProcessing">
          {{item.status || '--' | titlecase}}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
          [class.processing]="item.isProcessing">
          <ng-container *ngIf="item.email">{{item.email | lowercase}}</ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
          [class.processing]="item.isProcessing">
          <ng-container *ngIf="item.phone">{{item.phone | titlecase}}</ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="supervisor">
        <th mat-header-cell *matHeaderCellDef>Supervisor</th>
        <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
          [class.processing]="item.isProcessing">
          <ng-container *ngIf="item.supervisor">{{item.supervisor.profile.firstName | titlecase}}
            {{item.supervisor.profile.lastName | titlecase}}
            <br>
            <span class="sub-text">{{item.supervisor.code}}</span>
          </ng-container>
          <ng-container *ngIf="!item.supervisor">--</ng-container>
        </td>
      </ng-container>

      <ng-container *ngIf="!readonly" matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted" [class.processing]="item.isProcessing">
          <button mat-icon-button *ngIf="item.status !== 'deactivate'" [disabled]="item.isProcessing" matTooltip="Remove"
            (click)="onRemove(item)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>

    <mat-list *ngIf="view === 'list'">
      <a mat-list-item [routerLink]="[item.code]" class="list-group-item" *ngFor="let item of items"
        (click)="select(item)">
        <h4 mat-line>{{item.profile.firstName }} {{item.profile.lastName}}</h4>
        <p mat-line> {{item.code}} </p>
      </a>
    </mat-list>

    <app-paginator [pager]="pager" [total]="pager.total"></app-paginator>
  </ng-template>

  <ng-template #noData>
    <oa-no-data-found [message]="'No User Found'"></oa-no-data-found>
  </ng-template>
