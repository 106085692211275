import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {  } from './core/guards/role.guard';
import { PreventDirectNavigationGuard } from './core/guards/preventDirectNavigation.guard';
import { LoginComponent } from './modules/features/login/login.component';
import { GuestGuard } from './core/guards/guest.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { OnLoginGuard } from './core/guards/on-login.guard';
import { InactivityGuard } from './core/guards/inactivity.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('src/app/modules/features/login/login.module').then(
        (m) => m.LoginModule
      ),
    canActivate: [OnLoginGuard],
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('src/app/modules/features/landing/landing.module').then(
        (m) => m.LandingModule
      ),
    canActivate: [AuthGuard , InactivityGuard],
  },

  {
    path: 'home',
    loadChildren: () =>
      import('src/app/modules/unused/home/home.module').then(
        (m) => m.HomeModule
      ),
    canActivate: [AuthGuard , InactivityGuard],
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('src/app/modules/unused/projects/projects.module').then(
        (m) => m.ProjectsModule
      ),
    canActivate: [AuthGuard , InactivityGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('src/app/modules/features/reports/reports.module').then(
        (m) => m.ReportsModule
      ),
    canActivate: [AuthGuard , InactivityGuard],
  },
  {
    path: 'compute',
    loadChildren: () =>
      import('src/app/modules/features/compute-data/compute-data.module').then(
        (m) => m.ComputeDataModule
      ),
    canActivate: [AuthGuard , InactivityGuard],
  },
  {
    path: 'master',
    loadChildren: () =>
      import('src/app/modules/unused/master/master.module').then(
        (m) => m.MasterModule
      ),
    canActivate: [AuthGuard , InactivityGuard],
  },
  {
    path: 'user',
    loadChildren: () =>
      import('src/app/modules/features/user/user.module').then(
        (m) => m.UserModule
      ),
    canActivate: [AuthGuard , InactivityGuard],
  },
  {
    path: 'data',
    loadChildren: () =>
      import('src/app/modules/features/upload-data/upload-data.module').then(
        (m) => m.UploadDataModule
      ),
    canActivate: [AuthGuard , InactivityGuard],
  },
  {
    path: 'provider',
    loadChildren: () =>
      import('src/app/modules/features/provider/provider.module').then(
        (m) => m.ProviderModule
      ),
    canActivate: [AuthGuard , InactivityGuard],
  },
  {
    path: 'panel',
    loadChildren: () =>
      import('src/app/modules/features/panel/panel.module').then(
        (m) => m.PanelModule
      ),
    canActivate: [AuthGuard , InactivityGuard],
  },
  {
    path: 'zip-code',
    loadChildren: () =>
      import('src/app/modules/features/zip-code/zip-code.module').then(
        (m) => m.ZipCodeModule
      ),
    canActivate: [AuthGuard , InactivityGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('src/app/modules/unused/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivate: [AuthGuard, InactivityGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('src/app/modules/features/reports/reports.module').then(
        (m) => m.ReportsModule
      ),
    canActivate: [AuthGuard, InactivityGuard],
  },
  {
    path: 'model',
    loadChildren: () =>
      import('src/app/modules/features/model/model.module').then(
        (m) => m.ModelModule
      ),
    canActivate: [AuthGuard, InactivityGuard],
  },
  {
    path: 'add-virtual-provider',
    loadChildren: () =>
      import(
        'src/app/modules/features/add-virtual-provider/add-virtual-provider.module'
      ).then((m) => m.AddVirtualProviderModule),
    canActivate: [AuthGuard, InactivityGuard],
  },
  {
    path: 'add-provider-to-model',
    loadChildren: () =>
      import(
        'src/app/modules/features/add-provider-to-model/add-provider-to-model.module'
      ).then((m) => m.AddProviderToModelModule),
    canActivate: [AuthGuard, InactivityGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      // enableTracing: true
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
