<ng-container [ngSwitch]="from">
  <div class="form-container mx-4">
    <ng-container *ngSwitchCase="'login'">
      <div class="flex-row centered sub-text section-selector">Login to your account</div>
      <oa-error [(error)]="loginError" view="form-hint" [autoClear]="3"></oa-error>
      <!-- <div class="form-field">
        <label>Login Using</label>
        <div class="toggler">
          <span *ngFor="let item of idTypes" [class.active]="idType === item"
            (click)="idType = item">{{labelFor(item)}}</span>
        </div>
      </div> -->

      <!-- <ng-container [ngSwitch]="idType"> -->

      <oa-input type="text" label="User Name" [(value)]="email" [required]="true"> </oa-input>

      <!--
      <oa-input type="text" label="Employee No" [(value)]="employeeNo" [validate]="validateEmployeeNo" [required]="true"
        (errored)="onValidate($event)"> </oa-input>
    
      <oa-input type="phone" label="Mobile" [(value)]="mobile" [validate]="validateMobile" [required]="true"
        (errored)="onValidate($event)"> </oa-input>
      -->

      <oa-input label="Password" type="password" [(value)]="password" [required]="true" (errored)="onValidate($event)">
      </oa-input>
      <mat-hint>
        <!-- <div *ngIf="config.password.resetLink" class="paddingForgot"><a (click)="from = 'forgot-password'">Forgot
            Password?</a>
        </div> -->
        <!-- <div *ngIf="config.password.signupLink" class="paddingAccount">Not Registered? <a (click)="signUp()">Create
            Account</a>
        </div> -->
      </mat-hint>
      <div class="footer">
        <!-- <span class="spacer"></span> -->
        <button mat-raised-button color="primary"
          [disabled]="isProcessing || !(code || employeeNo || email || mobile) || !password || inputError"
          (click)="confirm()">Login</button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'forgot-password'">
      <auth-password-reset *ngIf="from === 'forgot-password'" (canceled)="from = 'login'">
      </auth-password-reset>
    </ng-container>
  </div>

</ng-container>