import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavService } from 'src/app/core/services';
import { RoleService } from 'src/app/lib/oa/core/services';
import { Action } from 'src/app/lib/oa/core/structures';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'oa-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit, OnChanges {

  @Input()
  item: Action | any;

  @Input()
  value: any;

  @Input()
  class: string;

  @Input()
  style: any;

  @Input()
  event: (obj?: any) => void;

  @Input()
  items: any[];

  @Input()
  disabled = false;

  @Input()
  type;

  @Input()
  view: string; // raised, stroked, flat, icon, fab, mini-fab

  @Output()
  selected: EventEmitter<any> = new EventEmitter();

  menu: Action[] = [];
  showList: boolean

  constructor(
    private navService: NavService,
    private auth: RoleService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {

    if (typeof this.item === 'string') {
      this.item = { code: this.item }
    }

    this.event = this.event || this.item.event;

    if (this.item.style) {
      if (typeof this.item.style === 'string') {
        this.class = this.item.style;
      } else {
        this.style = this.item.style
      }
    }

    if (!(this.item instanceof Action)) {
      this.item = new Action(this.item);
    }

    if (this.item && this.item.options && this.item.options.length) {
      this.items = this.item.options.map((o) => {
        const item = new Action(o);

        if (!item.event) {
          item.event = () => {
            this.item.event(item.value);
          };
        }
        return item;
      });
    }

    if (this.items && this.items.length) {
      this.menu = this.items.filter((i) => {
        return this.auth.hasPermission(i.permissions);
      }).map((i) => i instanceof Action ? i : new Action(i));
      this.item = this.item || new Action({
        code: 'more'
      });
    }

    if (!this.item) { return; }

    if (this.item.isDisabled && this.item.display === 'disabled') {
      this.disabled = true;
    }

    switch (this.item.code) {
      case 'save':
        this.item.icon = this.item.icon || 'mat-save';
        this.item.title = this.item.title || 'Save';
        break;

      case 'add':
        this.item.icon = this.item.icon || 'mat-add';
        this.item.title = this.item.title || 'New';
        break;

      case 'download':
        this.item.icon = this.item.icon || 'mat-download';
        this.item.title = this.item.title || 'Download';
        break;

      case 'remove':
        this.item.icon = this.item.icon || 'mat-delete_outline';
        this.item.title = this.item.title || 'Remove';
        break;

      case 'team':
        this.item.icon = this.item.icon || 'mat-people';
        this.item.title = this.item.title || 'Team';
        break;

      case 'expand':
        this.item.icon = this.item.icon || 'mat-expand_more';
        this.item.title = this.item.title || 'Expand';
        break;

      case 'collapse':
        this.item.icon = this.item.icon || 'mat-expand_less';
        this.item.title = this.item.title || 'Collapse';
        break;

      case 'done':
        this.item.icon = this.item.icon || 'mat-done_all';
        this.item.title = this.item.title || 'Show Done';
        break;

      case 'draft':
        this.item.icon = this.item.icon || 'mat-edit';
        this.item.title = this.item.title || 'Show Draft';
        break;

      case 'busy':
        this.item.icon = this.item.icon || 'fa-spinner fa-spin';
        this.item.title = this.item.title || 'Processing';
        this.disabled = true;
        break;

      case 'error':
        this.item.icon = this.item.icon || 'mat-warning';
        this.item.title = this.item.title || 'Errored';
        break;

      case 'edit':
        this.item.icon = this.item.icon || 'mat-edit';
        this.item.title = this.item.title || 'Edit';
        break;

      case 'copy':
        this.item.icon = this.item.icon || 'mat-file_copy';
        this.item.title = this.item.title || 'Copy';
        break;

      case 'restart':
      case 'reset':
        this.item.icon = this.item.icon || 'mat-restart_alt';
        this.item.title = this.item.title || 'Reset';
        break;

      case 'details':
        this.item.icon = this.item.icon || 'mat-chevron_right';
        this.item.title = this.item.title || 'Details';
        break;

      case 'more':
      case 'more-icons':
        this.item.icon = this.item.icon || 'mat-more_vert';
        this.item.title = this.item.title || 'More';
        this.item.type = this.item.type || 'more';
        break;

      case 'back':
        this.item.icon = this.item.icon || 'mat-arrow_back';
        this.item.title = this.item.title || 'Back';
        this.item.event = this.item.event || (() => this.navService.back());
        break;

      case 'clear':
      case 'close':
        this.item.icon = this.item.icon || 'mat-close';
        this.item.event = this.item.event || (() => this.navService.back());
        break;

      case 'search':
        this.item.title = this.item.title || 'Search';
        // this.item.type = this.item.type || 'search';
        this.item.icon = this.item.icon || 'mat-search';
        break;

      case 'filters':
        this.item.title = this.item.title || 'Toggle Filters';
        this.item.icon = this.item.icon || 'mat-filter_list';
        break;

      case 'grid':
        this.item.title = this.item.title || 'Grid';
        this.item.icon = this.item.icon || 'grid_view';
        break;

      case 'list':
        this.item.title = this.item.title || 'List';
        this.item.icon = this.item.icon || 'view_list';
        break;

      case 'table':
        this.item.title = this.item.title || 'Table';
        this.item.icon = this.item.icon || 'table_view';
        break;

      case 'columns':
        this.item.title = this.item.title || 'Column';
        this.item.icon = this.item.icon || 'view_column';
        break;

      case 'rows':
        this.item.title = this.item.title || 'Rows';
        this.item.icon = this.item.icon || 'view_list';
        break;

      case 'refresh':
        this.item.title = this.item.title || 'Reload';
        this.item.icon = this.item.icon || 'mat-refresh';
        break;

      case 'activities':
        this.item.title = this.item.title || 'Activities';
        this.item.icon = this.item.icon || 'mat-history';
        break;

      case 'help':
        this.item.title = this.item.title || 'Help';
        this.item.icon = this.item.icon || 'mat-help_outline';
        this.item.event = () => {
          this.navService.goto(`${environment.links.help}/${this.value}`);
        };
        break;

      case 'view':
        this.item.title = this.item.title || 'View';
        this.item.type = this.item.type || 'select';
        this.item.options = this.item.options.map((v) => {
          if (typeof v === 'string') {
            v = {
              code: v
            };
          }
          switch (v.code) {
            case 'table':
              v.label = v.label || 'Table';
              v.icon = v.icon || 'format_list_bulleted';
              break;

            case 'grid':
              v.label = v.label || 'Grid';
              v.icon = v.icon || 'grid_view';
              break;

            case 'column':
              v.label = v.label || 'Column';
              v.icon = v.icon || 'view_column';
              break;

            case 'list':
              v.label = v.label || 'List';
              v.icon = v.icon || 'view_list';
              break;
          }

          v.isSelected = false;
          return v;
        });

        this.item.options[0].isSelected = true;
        break;
    }

    this.type = this.type || this.item.type;

    if (!this.type) {
      this.type = this.item.icon ? 'icon' : 'button'
    }

    this.value = this.value || this.item.value;
  }

  ngOnInit() {
  }

  onToggle() {
    this.item.event(this.value);
  }

  onSelect(value: any) {
    this.item.options.forEach((i) => i.isSelected = false);
    value.isSelected = true;
    this.item.event(value.code);
  }

  onClick() {
    if (this.item.event) {
      this.item.event(this.value);
    }
    this.selected.emit(this.value);
  }

}
