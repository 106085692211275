import { FieldEditorModel } from "../../core/models";

export class ReportColumn extends FieldEditorModel {

  ascending?: boolean;
  filters?: any;
  showFilters?: any;
  isSticky?: boolean;

  isEmit?: boolean;
  click?: any;

  dbKey: string;
  identity?: boolean;
  formula?: string;

  keys?: string[];
  values?: string[];

  constructor(obj?: any) {
    super(obj);
    if (!obj) { return; }

    this.click = obj.click || {};
    this.ascending = obj.ascending;

    this.filters = obj.filters;
    this.isSticky = obj.isSticky || false;
    this.showFilters = obj.showFilters || false;

    this.keys = obj.keys || [];
    this.values = obj.values || [];

    this.dbKey = obj.dbKey;
    this.identity = obj.identity;
    this.formula = obj.formula;
  }
}
