<processing-indicator *ngIf="isProcessing"></processing-indicator>

<ng-container [ngSwitch]="view">
  <ng-container *ngSwitchCase="'list-item'">
    <ng-container *ngTemplateOutlet="listItem;"> </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'row'">
    <ng-container *ngTemplateOutlet="row;"> </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'expanded'">
    <ng-container *ngTemplateOutlet="expanded;"> </ng-container>
  </ng-container>
</ng-container>

<ng-template #listItem>
  <div class="list-item">
    <oa-input #inputText [preFix]="typeToggler"
      [options]="{ placeholder: placeholder, trigger: 'finish', changed: 'reset'}" (changed)="onCreate($event)">
    </oa-input>
  </div>
</ng-template>

<ng-template #row>
  <div class="flex-row">
    <oa-input #inputText [options]="{ inline: true, placeholder: placeholder, trigger: 'finish', changed: 'reset'}"
      (changed)="onCreate($event)">
    </oa-input>
  </div>
  <div class="footer">
    <oa-icon-toggler view="icon" [value]="type" [items]="types" (changed)="onSelectType($event)">
    </oa-icon-toggler>

    <oa-autocomplete [value]="sprint" [options]="{
      search: {
        items: sprints
      }
    }" (changed)="onSelectSprint($event)">
    </oa-autocomplete>

    <span class="spacer"></span>
    <directory-user-picker view="avatar" [value]="assignee" (changed)="onSelectAssignee($event)">
    </directory-user-picker>

    <oa-date-picker view="icon" [value]="dueDate" (changed)="onSelectDueDate($event)"></oa-date-picker>

    <oa-icon-toggler view="icon" [value]="priority" (changed)="onSelectPriority($event)" type="priority">
    </oa-icon-toggler>

    <button mat-raised-button (click)="createClicked()">Add</button>
  </div>
</ng-template>

<ng-template #expanded>
  <!-- <div class="flex-row two">
    <div>
      <label class="font">Title*</label>
      <div class="oa-input">
        <input [(ngModel)]="task.subject">
      </div>
    </div>

    <div>
      <gateway-workflow-list [label]="'Type'" [value]="{code: type}" (changed)="onSetWorkflow($event)">
      </gateway-workflow-list>
    </div>


    <div>
      <label>Start Date</label>
      <oa-date-picker [value]="task.plan.start" [minDate]="today" (change)="onSetStartDate($event)">
      </oa-date-picker>
    </div>

    <div>
      <label>Due Date</label>
      <oa-date-picker [disabled]="!task.plan.start" [minDate]="task.plan.start" [value]="task.plan.finish"
        (change)="onSetFinishDate($event)">
      </oa-date-picker>
    </div>

    <div>
      <directory-employee-picker [label]="'Assign To*'" (changed)="onSetAssignee($event)">
      </directory-employee-picker>
    </div>
  </div>

  <div>
    <label>Description</label>
    <div class="oa-textarea">
      <textarea [(ngModel)]="task.description"></textarea>
    </div>
  </div>

  <div>
    <label class="upload">Upload Documents</label>
    <div class="fileSection">
      <div class="removePad" *ngIf="fileList && fileList.length">
        <div *ngFor="let file of fileList; let i = index">{{file.name}}
          <div class="icon">
            <span class="fa fa-times-circle" aria-hidden="true" (click)="removeFile(i)">
            </span>
          </div>
          <hr>
        </div>
      </div>
      <div class="filePad">
        <oa-icon-file-picker [view]="'layout'" (change)="addFiles($event)">
          <div class="controls-row">
            <div>
              <span class="material-icons addIcon">
                add_circle
              </span>
            </div>
            <div class="chooseText">Choose file</div>
          </div>
        </oa-icon-file-picker>
      </div>
    </div>
  </div> -->
</ng-template>
