import { Component, Input, OnInit } from '@angular/core';
import { NavService, UxService } from 'src/app/core/services';
import { Action, Menu } from 'src/app/lib/oa/core/structures';

@Component({
  selector: 'oa-core-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css']
})
export class ContextMenuComponent implements OnInit {

  @Input()
  menu: Menu;

  @Input()
  view: 'bar' | 'inline' = 'bar';

  @Input()
  items: any[];

  constructor(
    private uxService: UxService,
    private navService: NavService

  ) {
    this.uxService.contextMenuChanges.subscribe(items => {
      this.items = items;
    });
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    if (this.menu?.items) {
      this.menu.items.forEach(i => {
        i.type = i.type || 'button';
        i.style = i.style || (i.type === 'icon' ? 'subtle' : 'default filled');
      })
    }
  }
  back() {
    this.navService.back();
  }

  toggle(item: Action) {
    item.value = !item.value;
    item.event(item.value);
  }

  onSelect(item: Action, value: any) {
    item.options.forEach((i) => i.isSelected = false);
    value.isSelected = true;
    item.event(value.code);
  }

}
