<div [class]="class" [style]="style">
  <label *ngIf="label && showLabel" [attr.for]="id" [class.required]="required">{{label}}</label>
  <ng-container [ngSwitch]="view">
    <ng-container *ngSwitchCase="'toggler'">
      <div class="toggler">
        <ng-container *ngFor="let item of items; let index=index;">
          <div [ngClass]="{'active': !value && index === 0 || value === item.value }" (click)="onSelect(item)">
            {{item.label}}
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'toggler-with-icons-and-stats'">
      <div class="toggler flex-row">
        <div class="controls-row" [ngClass]="{'active': !value && index === 0 || value === item.value }"
          (click)="onSelect(item)" *ngFor="let item of items; let index=index;">
          <mat-icon *ngIf="item?.icon">{{item.icon}}</mat-icon>
          <span [ngClass]="item.icon ? 'tab-label-before': 'tab-label-after'">{{item.label}}</span>
          <span *ngIf="item?.stat" class="tab-stat">{{item.stat}}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'list'">
      <mat-select [placeholder]="placeholder" [value]="value?.value" [disabled]="disabled">
        <mat-option *ngFor="let item of items" [value]="item.value" (click)="onSelect(item)">
          {{item.label || item.value || item}}
        </mat-option>
      </mat-select>
    </ng-container>

    <!-- <ng-container *ngSwitchCase="'list'">
      <div class="oa-input">
        <input [name]="label" [placeholder]="placeholder" (change)="addItem($event)" [disabled]="disabled">
      </div>

      <div *ngIf="items && items.length" class="my-1 py-2 px-2 rounded summary w-100">
        <div class="flex-row" *ngFor="let item of items; let i=index">
          <div>{{i+1}}. {{item.label}}</div>
          <span class="spacer"></span>
          <mat-icon *ngIf="!readonly" class="subtle md" (click)="removeItem(i)">
            close</mat-icon>
        </div>
      </div>
    </ng-container> -->

  </ng-container>
</div>
