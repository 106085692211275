<ng-container [ngSwitch]="view">
  <ng-container *ngSwitchCase="'container'">
    <div [class.ready-for-drop]="readyForDrop" [class.uploading]="isProcessing" class="drop-zone ">
      <div [attr.for]="inputId">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
      <input autocomplete="off" *ngIf="!acceptThis" class="file-input" multiple accept="{{types}}" [attr.id]="inputId"
        type="file" (change)="onFileSelected($event)" (click)="onClick()">
      <input autocomplete="off" *ngIf="acceptThis" multiple class="file-input" accept="{{types}}" [attr.id]="inputId"
        type="file" (change)="onFileSelected($event)">
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'dropZone'">
    <div [class.ready-for-drop]="readyForDrop" [class.uploading]="isProcessing" class="drop-zone ">
      <div>
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>