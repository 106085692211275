<processing-indicator *ngIf="isProcessing"></processing-indicator>

<div [ngSwitch]="view" *ngIf="items.length">
  <ng-container [ngSwitchCase]="'table'" *ngTemplateOutlet="table"></ng-container>
</div>

<ng-template #table>
  <table mat-table class="mat-elevation-z8" [dataSource]="items" multiTemplateDataRows>

    <ng-container matColumnDef="level">
      <th mat-header-cell *matHeaderCellDef> Level </th>
      <td mat-cell *matCellDef="let log" class="text-capitalize">
        <i class="md log-{{log.level}}" [matTooltip]="log.level"></i>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let log">
        <div>
          <p class="mb-1 mt-2"> {{log.timeStamp | time : 'merged'}}</p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="context">
      <th mat-header-cell *matHeaderCellDef> Context </th>
      <td mat-cell *matCellDef="let log">
        <span class="text-capitalize" (click)="serviceSelected.emit(log.app)">{{log.app}}</span>
        <span class="material-icons pointer" [matTooltip]="log.context?.id"
          (click)="contextSelected.emit(log.context.id)">repeat </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef> Message </th>
      <td mat-cell *matCellDef="let log">
        <div class="message">
          {{log.message.substr(0, 100)}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let log">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let log" [attr.colspan]="columns.length">
        <div class="example-element-detail" [@detailExpand]="log === expandedElement ? 'expanded' : 'collapsed'">
          <div class="example-element-description">
            {{log.message}}
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns;  sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: columns;" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>

  <app-paginator [pager]="pager" [total]="pager.total"></app-paginator>
</ng-template>

<oa-no-data-found *ngIf="!items.length"></oa-no-data-found>
