import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (!user || !user.meta || !user.meta.user || !user.meta.user.res) {
      this._router.navigate(['/login']);
      return false;
    }

    if (user && user.meta && user.meta.user && user.meta.user.res) {
      return true;
    } else {
      // Redirect to login page if not authenticated
      this._router.navigate(['/login']);
      return false;
    }
  }
}

