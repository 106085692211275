import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Pic } from 'src/app/lib/oa/core/models/pic.model';
import { RoleService } from 'src/app/lib/oa/core/services';
import { Organization, Role, Tenant } from 'src/app/lib/oa/directory/models';
import { Link } from 'src/app/lib/oa/core/structures';
import { ReportAreaService } from 'src/app/lib/oa/insight/services/report-area.service';
import { NavService } from '../../services/nav.service';
import { UxService } from '../../services/ux.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
})
export class SideNavComponent implements OnInit, OnDestroy {
  @Input()
  view: 'side' | 'header' = 'side'; // Set the default view to 'side'

  navs: Link[];
  active: Link[];
  currentTenant: Tenant;
  currentOrganization: Organization;
  currentRole: Role;

  selectedNav: Link = new Link({});

  logo: Pic;

  isExpanded: boolean = true;

  @Output()
  isExpand: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public auth: RoleService,
    public navService: NavService,
    public uxService: UxService,
    private reportAreaService: ReportAreaService,
    private router: Router,
    private _http: HttpService
  ) {
    auth.tenantChanges.subscribe((t) => {
      this.currentTenant = t;
      this.render();
    });
    auth.organizationChanges.subscribe((t) => {
      this.currentOrganization = t;
      this.render();
    });
    auth.roleChanges.subscribe((r) => {
      this.currentRole = r;
      this.render();
    });

    navService.navsChanges.subscribe((n) => {
      this.navs = (n || []).filter(
        (i) => this.auth.hasPermission(i.permissions) && i.view !== 'hidden'
      );
      this.fetchReportAreas();
    });
    uxService.logoChanges.subscribe((n) => (this.logo = n));
    navService.breadcrumbChanges.subscribe((links) => {
      this.active = links;
    });
  }

  ngOnInit() {
    this.render();
    this.extend();
  }

  isActive(nav: Link) {
    return (
      !!this.active &&
      this.active.length &&
      this.active.find((i) => i.code === nav.code)
    );
  }

  ngOnDestroy(): void {
    this.selectedNav = new Link({});
  }

  select(nav: Link) {
    // this.navs.forEach((i) => i.current = null);
    this.navs.forEach((i) => {
      if (nav.code === i.code) {
        i.isActive = true;
        if (i.current) {
          nav.current = null;
          i.current = null;
        } else {
          i.current = new Link();
          nav.current = new Link();
        }
      } else {
        i.isActive = false;
      }
    });
    this.navService.goto(nav);
  }

  fetchReportAreas() {
    if (!this.navs) {
      return;
    }
    const report = this.navs.find((n) => n.title === 'Reports');

    if (!report) {
      return;
    }

    if (!this.currentRole) {
      report.items = [];
      return;
    }

    if (report.items && report.items.length) {
      return;
    }
    this.reportAreaService.search().subscribe((page) => {
      report.items = page.items.map((i) => {
        return new Link({
          title: i.name,
          routerLink: ['/reports', i.code],
          permissions: i.permissions,
        });
      });
    });
  }

  render() {
    this.currentRole = this.auth.currentRole();
    this.currentTenant = this.auth.currentTenant();
    this.currentOrganization = this.auth.currentOrganization();
    this.logo = this.uxService.getLogo();
    let userType;

    let payload = {
      loginId: this.auth.currentUser().meta.user.query.username,
      password: this.auth.currentUser().meta.user.query.password,
    };
    this._http.post('/QMELogin/login', payload).subscribe((data: any) => {
      if (data.isSuccess) {
        userType = data.data.userType;
        this.auth._userType = userType;
        if (userType === 1) {
          this.navs = this.navService
            .getNavs()
            .filter(
              (i) =>
                this.auth.hasPermission(i.permissions) && i.view !== 'hidden'
            );
        } else {
          this.navs = this.navService.getNavs().filter((i) => {
            if (i.code === 'admin') {
              i.view = 'hidden';
            }
            return (
              this.auth.hasPermission(i.permissions) && i.view !== 'hidden'
            );
          });
        }
      }
    });
    this.fetchReportAreas();
  }

  extend() {
    // this.isExpanded = !this.isExpanded;
    this.isExpand.emit(this.isExpanded);
  }
}
