<div class="month">
  <i class="material-icons" (click)="previousMonth()">
    keyboard_arrow_left
  </i>
  <span class="monthYear">{{selectedDate.format('MMMM ')}} {{selectedDate.format('YYYY ')}}</span>
  <i class="material-icons" (click)="nextMonth()">
    keyboard_arrow_right
  </i>
</div>
<div class="calendar">
  <div class="weeks">
    <div class="weekcard">SUN</div>
    <div class="weekcard">MON</div>
    <div class="weekcard">TUE</div>
    <div class="weekcard">WED</div>
    <div class="weekcard">THU</div>
    <div class="weekcard">FRI</div>
    <div class="weekcard">SAT</div>
  </div>
  <div class="days">
    <ng-container *ngFor="let day of daysArr">
      <div class="daycard" (click)="openDialog(day)" [ngClass]="{'inactive ': day.status === 'disabled', 'selected': isSelected(day.date)}">
        <div class="dateRow" *ngIf="!day.events">
          <div></div>
          <div class="customPaddingDate" [ngClass]="{'today': todayCheck(day)}">{{day?.date | date: 'd'}}</div>
        </div>
        <div class="dateRow" *ngIf="day.events && day.events.length && !todayCheck(day)">
          <ng-container *ngTemplateOutlet="events; context:{items: day.events}"></ng-container>
          <div class="customPaddingDate" [ngClass]="{'holiday': day.events && day.events.length}">
            {{day?.date | date: 'd'}}
          </div>
        </div>
        <div class="dateRow" *ngIf="day.events && day.events.length && todayCheck(day)">
          <ng-container *ngTemplateOutlet="events; context:{items: day.events}"></ng-container>
          <div class="customPaddingDate" [ngClass]="{'todayHoliday': day.events && day.events.length}">
            {{day?.date | date: 'd'}}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #events let-items="items">
  <ng-container *ngFor="let event of items">
    <label *ngIf="event.hrs" class="hrs {{getHrsClass(event)}}">{{event.hrs}} hrs</label>
  </ng-container>
</ng-template>