<ng-container [ngSwitch]="!!groupBy">
  <ng-container *ngSwitchCase="true">
    <processing-indicator *ngIf="isProcessing"></processing-indicator>
    <ng-container *ngTemplateOutlet="groupedView"></ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="itemsView; context: {data: items}"></ng-container>
  </ng-container>

  <app-paginator [pager]="pager" [total]="pager.total"></app-paginator>
</ng-container>

<ng-template #groupedView>
  <ng-container [ngSwitch]="view">

    <ng-container *ngSwitchCase="'columns'">
      <div class="flex-1-1-1">
        <div class="section scrollable" *ngFor="let group of groups">
          <div class="header">
            <label> {{group.label}} <span class="stat disabled filled">{{group.items.length}}</span></label>
            <div class="spacer"></div>
            <div *ngIf="group.items && group.items.length">
              <mat-icon class="subtle md cursorPointer" [matTooltip]="'Copy'" (click)="copyText(group)">content_copy
              </mat-icon>
            </div>
          </div>
          <div class="body highlighted ">
            <div>
              <ng-container *ngTemplateOutlet="itemsView; context: {data: group.items}"></ng-container>
              <oa-no-data-found *ngIf="!group.items.length && group.nodata" [value]="group.nodata">
              </oa-no-data-found>
            </div>

            <div class="stacked drop-zone" [class.overlay]="selectedItem">
              <div class="spacer ready-for-drop" [class.hidden]="!selectedItem" (dragover)="onDragOver($event, group)"
                (drop)="onItemDrop($event, group)">
                {{group.label}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="sections">
        <ng-container *ngFor="let group of groups">
          <div class="divider">
            <label> {{group.label}} <span class="stat disabled filled">{{group.items.length}}</span></label>
            <div *ngIf="group.items && group.items.length">
              <mat-icon class="subtle md cursorPointer" [matTooltip]="'Copy'" (click)="copyText(group)">content_copy
              </mat-icon>
            </div>
          </div>
          <div class="section">
            <ng-container *ngTemplateOutlet="itemsView; context: {data: group.items}"></ng-container>
            <oa-no-data-found *ngIf="!group.items.length && group.nodata" [value]="group.nodata">
            </oa-no-data-found>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #itemsView let-data="data">
  <ng-container [ngSwitch]="view">

    <ng-container *ngSwitchCase="'table'">
      <processing-indicator *ngIf="isProcessing"></processing-indicator>
      <!-- <span>table</span> -->
      <ng-container *ngTemplateOutlet="tableView,context:{ items: data}"></ng-container>
    </ng-container>

    <div *ngSwitchCase="'shortList'" class="list">
      <processing-indicator *ngIf="isProcessing"></processing-indicator>
      <ng-container *ngIf="data && data.length">
        <div class="controls-row">
          <span class="spacer"></span>
          <app-paginator [pager]="pager" [total]="pager.total" [size]="pageSize" [view]="'previous'"></app-paginator>
          <span class="spacer"></span>
        </div>
        <div *ngFor="let item of items" class="controls-row list-item">
          <oa-avatar [user]="item.owner"></oa-avatar>
          <div class="spacer">
            <div class="controls-row">
              <div class="spacer">{{item.subject}}</div>
              <oa-goto-entity-button [view]="'icon'" [entity]="getEntity(item)"></oa-goto-entity-button>
            </div>
            <div class="controls-row">
              <div class="sub-text">{{item.owner | name}}</div>
              <span class="spacer"></span>
              <div class="sub-text">{{item.createdOn | time:'ago'}}</div>
            </div>
          </div>
          <hr>
        </div>
        <div class="controls-row">
          <span class="spacer"></span>
          <app-paginator [pager]="pager" [total]="pager.total" [size]="pageSize" [view]="'next'"></app-paginator>
          <span class="spacer"></span>
        </div>
      </ng-container>
      <oa-no-data-found *ngIf="!data || !data.length"></oa-no-data-found>
    </div>

    <div *ngSwitchCase="'sideBar'" class="sideBarList">
      <processing-indicator *ngIf="isProcessing"></processing-indicator>
      <ng-container *ngIf="data && data.length">
        <div class="controls-row">
          <span class="spacer"></span>
          <app-paginator [pager]="pager" [total]="pager.total" [size]="pageSize" [view]="'previous'"></app-paginator>
          <span class="spacer"></span>
        </div>
        <ng-container *ngFor="let item of items;let i=index;">
          <div class="controls-row" [class.closed]="item.isClosed">
            <oa-icon class="not-subtle md" [value]="item.workflow?.icon" [title]="item?.workflow?.name"></oa-icon>
            <div class="spacer">
              <div class="subject-text" [class.strike]="item.isClosed"
                [class.overdue]="!item.isClosed && isOverdue(item)">
                {{item.subject}}
              </div>
              <div class="controls-row">
                <div class="sub-text pointer status" matTooltip="{{item.timeStamp|time:'ago'}}">
                  {{item?.currentStatus?.name|name}}
                </div>
                <span class="spacer"></span>
                <ng-container *ngIf="!item.isClosed" class="controls-row list-icons">
                  <oa-icon-toggler [value]="item.priority" type="priority" (changed)="updatePriority(item, $event)">
                  </oa-icon-toggler>

                  <mat-icon class="md label pointer" [class.active]="item?.plan.reminder"
                    (click)="item.isSelected=!item.isSelected;from='reminder';selectedIndex=i"
                    matTooltip="{{item.plan.reminder|date:'dd/M/yyyy'}}">alarm</mat-icon>

                  <mat-icon class="md label pointer" [class.active]="item?.plan.start||item?.plan.finish"
                    (click)="item.isSelected=!item.isSelected;from='dueDate';selectedIndex=i"
                    matTooltip="Start date: {{item.plan.start|date:'dd/M/yyyy'}} End date: {{item.plan.finish|date:'dd/M/yyyy'}}">
                    calendar_today</mat-icon>

                  <div (click)="item.isSelected=!item.isSelected;from='assignee';selectedIndex=i">
                    <oa-avatar class="pointer" [user]="item.assignee" [size]="15">
                    </oa-avatar>
                  </div>

                  <div *ngIf="item.isSelected&&selectedIndex === i" class="date-picker section mat-elevation-z2">
                    <mat-icon class="md label pointer close" (click)="onClose(item)">close</mat-icon>
                    <oa-date-picker *ngIf="from === 'dueDate'" [placeholder]="'Start Date'" [value]="item.plan.start"
                      (change)="onChange($event,item,'startDate')">
                    </oa-date-picker>
                    <oa-date-picker *ngIf="from === 'reminder'||from === 'dueDate'"
                      [maxDate]="from === 'reminder'?item.plan.finish:null"
                      [placeholder]="from === 'reminder'?'Reminder':'Due Date'"
                      [value]="from === 'reminder'?item.plan.reminder:item.plan.finish"
                      (change)="onChange($event,item,from)">
                    </oa-date-picker>
                    <directory-user-picker *ngIf="from === 'assignee'" [placeholder]="'Assignee'"
                      [value]="item.assignee" (changed)="onChange($event,item,from)" [isAgent]="true">
                    </directory-user-picker>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>
          <hr>
        </ng-container>
        <div class="controls-row">
          <span class="spacer"></span>
          <app-paginator [pager]="pager" [total]="pager.total" [size]="pageSize" [view]="'next'"></app-paginator>
          <span class="spacer"></span>
        </div>
      </ng-container>
      <oa-no-data-found *ngIf="!items || !items.length"></oa-no-data-found>
    </div>

    <div *ngSwitchCase="'columns'" class="cards">
      <processing-indicator *ngIf="isProcessing"></processing-indicator>
      <ng-container *ngFor="let item of data | sort: orderBy">
        <ng-container *ngTemplateOutlet="columnCard; context: { item: item }"> </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="'release'" class="cards">
      <processing-indicator *ngIf="isProcessing" [inline]="true" [view]="'spinball'"></processing-indicator>
      <div class="controls-row" *ngIf="data && data.length">
        <button *ngIf="bulkAttachBtn" mat-raised-button class="primary" (click)="bulkAttach(data)">Select All</button>
        <button *ngIf="bulkDetachBtn" mat-raised-button class="primary" (click)="bulkDetach(data)">Unselect
          All</button>
      </div>
      <ng-container *ngFor="let item of data | sort: orderBy">
        <ng-container *ngTemplateOutlet="releaseCard; context: { item: item }"> </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchDefault class="cards">
      <processing-indicator *ngIf="isProcessing"></processing-indicator>
      <ng-container *ngFor="let item of data | sort: orderBy">
        <ng-container *ngTemplateOutlet="rowCard; context: { item: item }"> </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #noData>
  <oa-no-data-found value="all-done"></oa-no-data-found>
</ng-template>

<ng-template #tableView let-items="items">
  <processing-indicator *ngIf="isProcessing"></processing-indicator>
  <table *ngIf="items.length; else noData" mat-table [dataSource]="items">
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef>S.No</th>
      <td mat-cell *matCellDef="let item;let i=index;" [class.processing]="item.isProcessing">
        {{i+1}}
      </td>
    </ng-container>

    <ng-container matColumnDef="priority">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <i class="fa fa-flag" [class.high]="item.priority === 1" [class.medium]="item.priority === 2"
          [class.low]="item.priority>3"></i>
      </td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>Code</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.code | uppercase}}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.type}}
      </td>
    </ng-container>

    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <oa-icon class="not-subtle md" [value]="item.workflow?.icon" [title]="item?.workflow?.name"></oa-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.createdOn | time: 'merged'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="requester">
      <th mat-header-cell *matHeaderCellDef>Requester</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.owner | name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef>Details</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.subject}}
        <br>
        <span class="sub-text" [innerHTML]="item.description">{{item.description}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="request">
      <th mat-header-cell *matHeaderCellDef>Request Type</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.type}}
      </td>
    </ng-container>

    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef>Subject</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <span [innerHTML]="item.subject"></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="entity">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <div class="controls-row">
          <span class="spacer"></span>
          <oa-goto-entity-button [view]="'icon'" [entity]="item.entity"></oa-goto-entity-button>

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <span [innerHTML]="item.description"></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="estimate">
      <th mat-header-cell *matHeaderCellDef>Estimate</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.size}}
      </td>
    </ng-container>

    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef>Start</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.plan.start?(item.plan.start | date: 'd MMM, y'):'-- --'}}
        <p class="sub-text">
          {{item.plan.start | date: 'h:mm a'}}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="end">
      <th mat-header-cell *matHeaderCellDef>End</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.plan.finish?(item.plan.finish | date: 'd MMM, y'):'-- --'}}
        <p class="sub-text">
          {{item.plan.finish | date: 'h:mm a'}}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="delay">
      <th mat-header-cell *matHeaderCellDef>Delay</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{getDelay(item)}} hours(s)
      </td>
    </ng-container>

    <ng-container matColumnDef="assignee">
      <th mat-header-cell *matHeaderCellDef>Assignee</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <div class="controls-row" *ngIf="item.assignee">
          <oa-avatar class="pointer spacing" [user]="item.assignee" [size]="25"></oa-avatar>
          {{item.assignee | name}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.currentStatus.name | uppercase}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <div class="controls-row">
          <gateway-task-actions [task]="item" (updated)="onRefresh()">
          </gateway-task-actions>
          <span class="spacer"></span>
          <oa-goto-entity-button *ngIf="showGoto" [title]="'View'" [entity]="getEntity(item)">
          </oa-goto-entity-button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="orgName">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td class="pointer org-name" (click)="select(item)" mat-cell *matCellDef="let item"
        [class.deleted]="item.isDeleted" [class.processing]="item.isProcessing">
        <img *ngIf="item.logo && item.logo.url" [src]="item.logo.url">
        <img *ngIf="!item.logo || !item.logo.url" src="/assets/images/customers/default.png">
        <span>{{item.meta.organization.name}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="orgEmail">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
        [class.processing]="item.isProcessing">
        {{item.meta.organization.email || '--'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="orgPhone">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td class="pointer" (click)="select(item)" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
        [class.processing]="item.isProcessing">
        {{item.meta.organization.phone || '--'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="orgPersonName">
      <th mat-header-cell *matHeaderCellDef>Contact Person</th>
      <td class="pointer" mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted"
        [class.processing]="item.isProcessing">{{item.meta.organization?.meta?.contactPerson || '--'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item" [class.deleted]="item.isDeleted" [class.processing]="item.isProcessing">
        <button mat-icon-button [disabled]="item.isProcessing" matTooltip="Edit" (click)="select(item)">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="planstart">
      <th mat-header-cell *matHeaderCellDef>Plan Start Date</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.plan.start?(item.plan.start | date: 'd MMM, y'):'-- --'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="planfinish">
      <th mat-header-cell *matHeaderCellDef>Plan End Date</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        {{item.plan.finish?(item.plan.finish | date: 'd MMM, y'):'-- --'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="planName">
      <th mat-header-cell *matHeaderCellDef>PlanName</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <span *ngIf="item?.meta?.subscription?.plan?.name">{{item.meta.subscription.plan.name}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <span class="text-capitalize"
          *ngIf="item?.meta?.subscription?.amount">{{item?.meta?.subscription?.plan?.currency?.symbol ||
          '₹'}} {{item.meta.subscription.amount}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef>Duration</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <span>{{item?.meta?.subscription?.duration}}
          {{item?.meta?.subscription?.interval}}{{item?.meta?.subscription?.duration > 1 ? 's' : ''}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actionItem">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <div class="controls-row">
          <gateway-task-actions [task]="item" [view]="'action'" (updated)="onRefresh()">
          </gateway-task-actions>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <mat-checkbox class="checkBox" [color]="'primary'" [checked]="item.isSelected" (change)="select(item)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="multi-select">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item" [class.processing]="item.isProcessing">
        <mat-checkbox class="checkBox" [color]="'primary'" [checked]="item.isSelected"
          (change)="item.isSelected = $event.checked">
        </mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true">
    </tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;">
    </tr>
  </table>
</ng-template>

<!-- <ng-template #sprintView let-items="items">
  <div *ngFor="let sprint of sprints | sort: 'plan.end'" class="section drop-zone" [class.closed]="sprint.isClosed"
    [class.overdue]="!sprint.isClosed && isOverdue(sprint)" [class.ready-for-drop]="selectedItem && sprint.isEditing"
    (dragover)="onDragOver($event, sprint.id, 'sprint')" (drop)="onItemDrop($event, sprint.id, 'sprint')">
    <div class="header">
      <mat-icon class="x-lg label pointer" (click)="toggleSprint(sprint)">
        {{sprint.isSelected ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
      </mat-icon>
      <span (click)="toggleSprint(sprint)">{{sprint.name}}:</span>
      <span class="spacer"></span>
      <ng-container *ngIf="sprint.code !== 'backlog'">
        <span *ngIf="sprint.plan && sprint.plan.finish" class="sub-text">{{sprint.plan.finish | time: 'ago'}}</span>
        <mat-icon class="subtle" (click)="onEditSprint(sprint)">more_vert</mat-icon>
      </ng-container>
      <a *ngIf="sprint.code === 'backlog'" mat-button color="accent" (click)="onNewSprint()">New Sprint</a>
    </div>

    <div class="table" *ngIf="sprint.isSelected">
      <processing-indicator *ngIf="sprint.isProcessing"></processing-indicator>
      <ng-container *ngFor="let item of sprint.tasks | search: params | sort: orderBy">
        <ng-container *ngTemplateOutlet="rowItem; context: { item: item }"> </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #kanbanView let-items="items">
  <div class="flex-1-1-1">
    <div class="section scrollable">
      <div class="header">
        <label>Not started</label>
      </div>
      <div class="body highlighted">
        <div>
          <ng-container *ngFor="let item of reworkItems| search: params | sort: orderBy">
            <ng-container *ngTemplateOutlet="columnCard; context: { item: item }"> </ng-container>
          </ng-container>
          <ng-container *ngFor="let item of notStartedItems| search: params | sort: orderBy">
            <ng-container *ngTemplateOutlet="columnCard; context: { item: item }"> </ng-container>
          </ng-container>
        </div>

        <div class="stacked drop-zone" [class.overlay]="selectedItem">
          <div class="spacer" [class.ready-for-drop]="reworkZone" [class.hidden]="!selectedItem || !reworkZone"
            (dragover)="onDragOver($event, 'rework', 'kanban')" (drop)="onItemDrop($event, 'rework', 'kanban')">Rework
          </div>
          <div class="spacer" [class.ready-for-drop]="notStartedZone" [class.hidden]="!selectedItem || !notStartedZone"
            (dragover)="onDragOver($event, 'not-started', 'kanban')"
            (drop)="onItemDrop($event, 'not-started', 'kanban')">New</div>
        </div>

      </div>
    </div>
    <div class="section scrollable">
      <div class="header">
        <label>In Progress</label>
      </div>
      <div class="body highlighted">
        <div>
          <ng-container *ngFor="let item of wipItems| search: params | sort: orderBy">
            <ng-container *ngTemplateOutlet="columnCard; context: { item: item }"> </ng-container>
          </ng-container>
        </div>

        <div class="stacked drop-zone" [class.overlay]="selectedItem">
          <div class="spacer" [class.ready-for-drop]="wipZone" [class.hidden]="!wipZone"
            (dragover)="onDragOver($event, 'wip', 'kanban')" (drop)="onItemDrop($event, 'wip', 'kanban')">In Progress
          </div>
        </div>
      </div>
    </div>

    <div class="section scrollable">
      <div class="header">
        <label>Done</label>
      </div>
      <div class="body highlighted">
        <div>
          <ng-container *ngFor="let item of resolvedItems| search: params | sort: orderBy">
            <ng-container *ngTemplateOutlet="columnCard; context: { item: item }"> </ng-container>
          </ng-container>
          <ng-container *ngFor="let item of completedItems| search: params | sort: orderBy">
            <ng-container *ngTemplateOutlet="columnCard; context: { item: item }"> </ng-container>
          </ng-container>
          <ng-container *ngFor="let item of rejectedItems| search: params | sort: orderBy">
            <ng-container *ngTemplateOutlet="columnCard; context: { item: item }"> </ng-container>
          </ng-container>
        </div>
        <div class="stacked drop-zone" [class.overlay]="selectedItem">
          <div class="spacer" [class.ready-for-drop]="resolvedZone" [class.hidden]="!resolvedZone"
            (dragover)="onDragOver($event, 'resolved', 'kanban')" (drop)="onItemDrop($event, 'resolved', 'kanban')">
            Resolve</div>
          <div class="spacer" [class.ready-for-drop]="completedZone" [class.hidden]="!completedZone"
            (dragover)="onDragOver($event, 'completed', 'kanban')" (drop)="onItemDrop($event, 'completed', 'kanban')">
            Complete</div>
          <div class="spacer" [class.ready-for-drop]="rejectedZone" [class.hidden]="!rejectedZone"
            (dragover)="onDragOver($event, 'rejected', 'kanban')" (drop)="onItemDrop($event, 'rejected', 'kanban')">
            Reject</div>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->

<ng-template #columnCard let-item="item">

  <div draggable="true" class="section card draggable priority priority-{{item.priority || 3}}"
    [class.selected]="selectedItem && selectedItem.id === item.id"
    [class.hidden]="selectedItem && selectedItem.id !== item.id" (dragstart)="onItemDragStart($event, item)"
    (dragend)="onItemDragEnd($event, item)">
    <div class="header">
      <oa-icon class="not-subtle md" [value]="item.workflow.icon" [title]="item?.workflow?.name"></oa-icon>
      <div class="controls-row">
        <ng-container *ngIf="item.parent">
          <span (click)="select(item.parent)" class="clickable code">{{item.parent.code}}</span>
          <span>&raquo;</span>
        </ng-container>
        <span (click)="select(item)" class="clickable code">{{item.code}}</span>
      </div>
      <span class="spacer"></span>
      <gateway-category-picker [value]="item.category" [view]="'readonly'"></gateway-category-picker>
      <span class="spacer"></span>
      <gateway-task-states [task]="item" view="pill"></gateway-task-states>
      <!-- <span class="pill-box handle">{{item.currentStatus?.name}}</span> -->
    </div>
    <div class="handle">
      <div>{{item.subject}}</div>
      <div *ngIf="item.parent" class="sub-text">{{item.parent.subject}}</div>
      <div class="flex-row">
        <span class="spacer"></span>
        <div class="sub-text">- {{item.owner | name }}</div>
      </div>
    </div>

    <!-- <hr class="divider"> -->

    <div class="body highlighted handle scrollable description">
      <div [innerHTML]="item.description"></div>
    </div>

    <div class="controls-row">
      <span class="code">{{item?.sprint?.name}}</span>
      <span class="spacer"></span>
      <div class="tags">
        <span *ngFor="let tag of item.tags" (click)="onSelectedTag.emit(tag)">{{tag}}</span>
      </div>
    </div>

    <div class="footer">
      <directory-user-picker view="avatar" placeholder="Assignee" [value]="item.assignee"
        (changed)="updateAssignee(item, $event)">
      </directory-user-picker>
      <oa-icon-toggler type="priority" [value]="item.priority" [items]="priorities"
        (changed)="updatePriority(item, $event)">
      </oa-icon-toggler>
      <div *ngIf="item.workflow?.children?.length">
        <oa-icon-toggler view="icon" type="points" [items]="points" [value]="item.points"
          (changed)="updatePoints(item, $event)">
        </oa-icon-toggler>
      </div>
      <span class="spacer"></span>
      <div *ngIf="(item.burnt || item.size)" class="hide-on-hover pill-box" [class.with-value]="item.size"
        [class.warn]="item?.burnt > item?.size">
        <span *ngIf="item.burnt">{{item.burnt*60 |time: 'clockWiseShort'}}</span>
        <span *ngIf="item.size" class="value">{{item.size*60 |time: 'clockWiseShort'}}</span>
      </div>
      <span class="spacer"></span>
      <oa-date-picker *ngIf="!item.isClosed" view="icon" [value]="item?.plan.finish"
        (change)="updateDueDate(item, $event)">
      </oa-date-picker>
    </div>
  </div>

</ng-template>

<ng-template #rowItem let-item="item">
  <div draggable="true" class="data-row draggable vertical priority priority-{{item.priority || 3}}"
    [class.selected]="selectedItem && selectedItem.id === item.id" [class.closed]="item.isClosed"
    [class.overdue]="!item.isClosed && isOverdue(item)" (dragstart)="onItemDragStart($event, item)"
    (dragend)="onItemDragEnd($event, item)">
    <div *ngIf="columns.priority" class="vertical priority priority-{{item.priority || 3}}"></div>
    <i *ngIf="columns.order" class="md drag-grab handle"></i>
    <div class="spacer">
      <div class="flex-row subtle">
        <oa-icon *ngIf="columns.workflow" class="not-subtle md" [value]="item.workflow?.icon"
          [title]="item?.workflow?.name"></oa-icon>
        <div *ngIf="columns.id" class="primary">
          <a (click)="select(item)">{{item.code | uppercase }}</a>
        </div>
        <oa-input *ngIf="columns.subject" [value]="item.subject" [options]="{ inline: true, trigger: 'finish'}"
          (changed)="updateSubject(item, $event)" (selected)="item.isEditing = $event"></oa-input>

        <ng-container *ngIf="!item.isEditing">
          <oa-icon *ngIf="columns.edit" class="subtle clickable show-on-hover" value="edit"
            (click)="item.isEditing = true"></oa-icon>
          <oa-icon-toggler *ngIf="columns.priorityToggler" class="show-on-hover" type="priority" [items]="priorities"
            [value]="item.priority || 3" (changed)="updatePriority(item, $event)">
          </oa-icon-toggler>

          <div class="tags">
            <span *ngFor="let tag of item.tags" (click)="onSelectedTag.emit(tag)">{{tag}}</span>
          </div>

          <div *ngIf="columns.actions" class="show-on-hover">
            <gateway-task-actions view="action" [task]="item" [options]="{ more: { hide: true } }">
            </gateway-task-actions>
          </div>
          <div *ngIf="(item.burnt || item.size)" class="hide-on-hover pill-box " [class.with-value]="item.size"
            [class.warn]="item?.burnt > item?.size">
            <span *ngIf="item.burnt">{{item.burnt*60 |time: 'clockWiseShort'}}</span>
            <span *ngIf="item.size" class="value">{{item.size*60 |time: 'clockWiseShort'}}</span>
          </div>
          <div *ngIf="columns.points && item.workflow?.children?.length">
            <oa-icon-toggler view="icon" type="points" [items]="points" [value]="item.points"
              (changed)="updatePoints(item, $event)">
            </oa-icon-toggler>
          </div>
          <span *ngIf="columns.status" class="hide-on-hover pill-box"> {{item.currentStatus.name}}</span>
          <ng-container *ngIf="columns.assignee">
            <directory-user-picker *ngIf="!item.isClosed" view="avatar" [value]="item.assignee"
              (changed)="updateAssignee(item, $event)">
            </directory-user-picker>
          </ng-container>
          <ng-container *ngIf="columns.planFinish">
            <oa-date-picker *ngIf="!item.isClosed" view="icon" [value]="item?.plan.finish"
              (change)="updateDueDate(item, $event)">
            </oa-date-picker>
          </ng-container>
          <oa-avatar *ngIf="columns.owner" [value]="item.owner"></oa-avatar>
          <oa-icon *ngIf="columns.view" class="subtle clickable" [title]="'View'" value="chevron_right"
            (click)="select(item)"></oa-icon>
        </ng-container>
        <oa-icon *ngIf="item.isEditing" class="subtle" value="close" (click)="item.isEditing = false"></oa-icon>
      </div>
      <div class="flex-row" *ngIf="item.isEditing">
        <div>
          <label>Priority/Assignee</label>
          <div class="flex-row">
            <oa-icon-toggler view="icon" type="priority" [items]="priorities" [value]="item.priority || 3"
              (changed)="updatePriority(item, $event)">
            </oa-icon-toggler>
            <directory-user-picker placeholder="Assignee" [value]="item.assignee" [readonly]="item.isClosed"
              (changed)="updateAssignee(item, $event)">
            </directory-user-picker>
          </div>
        </div>
        <span class="spacer"></span>
        <div *ngIf="columns.points && item.workflow?.children?.length">
          <label>Points</label>
          <oa-icon-toggler view="icon" type="points" [items]="points" [value]="item.points"
            (changed)="updatePoints(item, $event)">
          </oa-icon-toggler>
        </div>
        <div class="oa-input-group sm">
          <label>Effort</label>
          <oa-value-editor [value]="{
            value: item.size || 0,
            unit: { code: 'min' }
          }" type="span" (changed)="updateSize(item, $event)"></oa-value-editor>
        </div>
        <div *ngIf="!item.isClosed && columns.planFinish" class="oa-input-group">
          <label>Due Date</label>
          <oa-date-picker view="date" [value]="item?.plan.finish" (change)="updateDueDate(item, $event)">
          </oa-date-picker>
        </div>
        <div *ngIf="!item.isClosed && columns.sprint">
          <label>Sprint</label>
          <gateway-sprint-list [value]="item.sprint" [items]="project?.sprints" (changed)="updateSprint(item, $event)">
          </gateway-sprint-list>
        </div>
        <span class="spacer"></span>
        <div>
          <gateway-category-picker [label]="'Category'" [searchField]="'text'" [params]="{
            'project-id': (item.project && item.project.id) ? item.project.id: null
          }" [value]="item.category" (changed)="updateCategory(item, $event)">
          </gateway-category-picker>
        </div>
        <span class="spacer"></span>
        <div>
          <label>Action</label>
          <gateway-task-actions *ngIf="columns.actions" view="action" [task]="item">
          </gateway-task-actions>
        </div>
      </div>
    </div>
  </div>
  <!-- <div draggable="true" class="data-row draggable subtle" [class.selected]="selectedItem && selectedItem.id === item.id"
    [class.closed]="item.isClosed" [class.overdue]="!item.isClosed && isOverdue(item)"
    (dragstart)="onItemDragStart($event, item)" (dragend)="onItemDragEnd($event, item)">
    <ng-container *ngFor="let column of columns" [ngSwitch]="column">
      <div *ngSwitchCase="'priority'" class="vertical priority priority-{{item.priority || 3}}"></div>
      <i *ngSwitchCase="'re-order'" class="md drag-grab handle"></i>
      <oa-icon *ngSwitchCase="'workflow'" class="not-subtle md" [value]="item.workflow?.icon"
        [title]="item?.workflow?.name"></oa-icon>
      <oa-input *ngSwitchCase="'subject'" [value]="item.subject" [options]="{ inline: true, trigger: 'finish'}"
        (changed)="updateSubject(item, $event)" (selected)="item.isEditing = $event">
      </oa-input>
    </ng-container>

    <ng-container *ngIf="!item.isEditing">
      <ng-container *ngFor="let column of columns" [ngSwitch]="column">
        <span *ngSwitchCase="'status'" class="hide-on-hover pill-box"> {{item.currentStatus.name}}</span>
        <div *ngSwitchCase="'effort'" class="hide-on-hover pill-box">
          <span *ngIf="item.burnt">{{item.burnt*60 |time: 'clockWiseShort'}}</span><span *ngIf="item.burnt && item.size">/</span>
          <span *ngIf="item.size">{{item.size*60 |time: 'clockWiseShort'}}</span>
        </div>
        <div *ngSwitchCase="'actions'" class="show-on-hover">
          <gateway-task-actions view="action" [task]="item" [options]="{ more: { hide: true } }">
          </gateway-task-actions>
        </div>
        <div *ngSwitchCase="'priority-toggler'" class="show-on-hover">
          <oa-icon-toggler type="priority" [items]="priorities" [value]="item.priority || 3"
            (changed)="updatePriority(item, $event)">
          </oa-icon-toggler>
        </div>
        <div *ngSwitchCase="'points'">
          <oa-icon-toggler view="icon" type="points" [items]="points" [value]="item.points"
            (changed)="updatePoints(item, $event)">
          </oa-icon-toggler>
        </div>
        <ng-container *ngSwitchCase="'assignee'">
          <directory-user-picker *ngIf="!item.isClosed" view="avatar" label="Assignee" [value]="item.assignee"
            (changed)="updateAssignee(item, $event)">
          </directory-user-picker>
        </ng-container>
        <ng-container *ngSwitchCase="'plan-finish'">
          <oa-date-picker *ngIf="!item.isClosed" view="icon" [value]="item?.plan.finish"
            (change)="updateDueDate(item, $event)">
          </oa-date-picker>

        </ng-container>
        <a *ngSwitchCase="'view'" mat-button color="accent" (click)="select(item)" class="clickable">{{item.code
          | uppercase }}</a>
      </ng-container>
    </ng-container>

  </div> -->
</ng-template>

<ng-template #rowCard let-item="item">
  <div draggable="true" class="section card draggable vertical priority priority-{{item.priority || 3}}">
    <div class="subtle" [class.flex-row]="!item.isSelected" [class.header]="item.isSelected">

      <oa-icon *ngIf="tableColumns.includes('icon')" class="not-subtle md" [value]="item.workflow?.icon"
        [title]="item?.workflow?.name"></oa-icon>

      <div *ngIf="tableColumns.includes('code')" class="primary">
        <a (click)="select(item)">{{item.code | uppercase }}</a>
        <div *ngIf="item.parent && !parent" class="sub-text clickable code" (click)="select(item.parent)">
          {{item.parent.code}}
        </div>
      </div>

      <ng-container *ngIf="!item.isSelected">

        <ng-container *ngIf="tableColumns.includes('subject')">
          <div class="spacer" *ngIf="!item.isEditing" (click)="item.isEditing = true">
            <div [innerHTML]="item.subject"></div>
            <div *ngIf="item.parent && !parent" class="sub-text">
              {{item.parent.subject}}
            </div>
          </div>
          <div class="input subject spacer" *ngIf="item.isEditing" [class.selected]="item.isEditing"
            (focus)="item.isEditing = true" contenteditable="true" (blur)="updateSubject(item, $event)">
            {{item.subject}}
          </div>
        </ng-container>

        <div *ngIf="tableColumns.includes('effort')" class="hide-on-hover">
          <oa-progress [view]="'pill'" [value]="item.burnt" [total]="item.size" [type]="'effort'"></oa-progress>
        </div>

        <div *ngIf="tableColumns.includes('tags')" class="hide-on-hover">
          <oa-tags [values]="item.tags" [readonly]="true"></oa-tags>
        </div>

        <div *ngIf="tableColumns.includes('status')" class="hide-on-hover">
          <gateway-task-states [task]="item" view="pill"></gateway-task-states>
        </div>

        <div *ngIf="tableColumns.includes('actions')" class="show-on-hover inline">
          <gateway-task-actions view="action" [task]="item" [options]="{ more: { hide: true } }">
          </gateway-task-actions>
        </div>

        <div *ngIf="tableColumns.includes('progress')" class="hide-on-hover">
          <oa-progress [view]="'progress'" [content]="'total'" [value]="item?.meta.closedPoints" [total]="item.points"
            [size]="30"></oa-progress>
        </div>

        <gateway-category-picker *ngIf="tableColumns.includes('category')" [view]="'picker'" [searchField]="'text'"
          [params]="{
            'project-id': (item.project && item.project.id) ? item.project.id: null
          }" [value]="item.category" (changed)="updateCategory(item, $event)">
        </gateway-category-picker>

        <directory-user-picker *ngIf="tableColumns.includes('assignee')" view="avatar" [value]="item.assignee"
          (changed)="updateAssignee(item, $event)">
        </directory-user-picker>

        <oa-date-picker view="icon" *ngIf="tableColumns.includes('dueDate')" [value]="item?.plan?.finish"
          (change)="updateDueDate(item, $event)">
        </oa-date-picker>

      </ng-container>

      <ng-container *ngIf="item.isSelected">
        <div class="input subject spacer" [class.selected]="item.isEditing" (focus)="item.isEditing = true"
          contenteditable="true" (blur)="updateSubject(item, $event)">
          {{item.subject}}
        </div>
      </ng-container>
      <oa-icon-toggler type="expand" [value]="item.isSelected" (changed)="item.isSelected = $event"></oa-icon-toggler>
    </div>
    <ng-container *ngIf="item.isSelected">
      <div class="body">
        <ng-container *ngIf="detailsTemplate">
          <ng-container *ngTemplateOutlet="detailsTemplate; context: { task: item }"></ng-container>
        </ng-container>
        <gateway-task-details *ngIf="!detailsTemplate" view="row" [properties]="item"></gateway-task-details>
      </div>
      <div class="footer" *ngIf="footer === 'statusbar'">
        <gateway-task-status-bar class="spacer" [skipAction]="false" [task]="item" [code]="item?.code"
          (onSelect)="select($event)" [arrow]="'forward'" [options]="getOptions(item)">
        </gateway-task-status-bar>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #rowContent let-item="item">


</ng-template>

<ng-template #releaseCard let-item="item">
  <div draggable="true" class="section card draggable vertical priority priority-{{item.priority || 3}}">
    <div class="subtle" [class.flex-row]="!item.isSelected" [class.header]="item.isSelected">
      <mat-checkbox *ngIf="showCheckBox" [checked]="item.release && item.release.code"
        (change)="setRelease($event,item)"></mat-checkbox>
      <oa-icon class="not-subtle md" [value]="item.workflow?.icon" [title]="item?.workflow?.name"></oa-icon>
      <div class="primary">
        <a (click)="select(item)">{{item.code | uppercase }}</a>
        <div *ngIf="item.parent && !parent" class="sub-text" (click)="select(item.parent)" class="clickable">
          {{item.parent.code | uppercase}}
        </div>
      </div>
      <div class="spacer">
        <div [innerHTML]="item.subject"></div>
        <div *ngIf="item.parent && !parent" class="sub-text">
          {{item.parent.subject}}
        </div>
      </div>
      <div class="hide-on-hover">
        <oa-tags [values]="item.tags" [readonly]="true"></oa-tags>
      </div>
      <directory-user-picker view="avatar" [readonly]="true" [value]="item.assignee"
        (changed)="updateAssignee(item, $event)">
      </directory-user-picker>
      <oa-icon-toggler type="expand" [value]="item.isSelected" (changed)="item.isSelected = $event"></oa-icon-toggler>
    </div>
    <ng-container *ngIf="item.isSelected">
      <div class="body">
        <ng-container *ngIf="detailsTemplate">
          <ng-container *ngTemplateOutlet="detailsTemplate; context: { task: item }"></ng-container>
        </ng-container>
        <gateway-task-details *ngIf="!detailsTemplate" view="row" [properties]="item"></gateway-task-details>
      </div>
      <div class="footer" *ngIf="footer === 'statusbar'">
        <gateway-task-status-bar class="spacer" [skipAction]="false" [task]="item" [code]="item?.code"
          (onSelect)="select($event)" [arrow]="'forward'" [options]="getOptions(item)">
        </gateway-task-status-bar>
      </div>
    </ng-container>
  </div>
</ng-template>
