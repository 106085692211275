<div class="table">
    <div class="header flex-row" [class.readonly]="readonly">
        <div class="date">Date</div>
        <div class="activity">Activity</div>
        <div class="task">Task</div>
        <div class="effort">Effort</div>
        <div class="comment">Comment</div>
        <div class="action">Action</div>
    </div>

    <div class="data-row subtle">
        <div class="date">
            <oa-date-picker (change)="onSelectDate($event)" [value]="timeLog.date">
            </oa-date-picker>
        </div>

        <div class="activity">
            <mat-select [(ngModel)]="timeLog.activity">
                <mat-option *ngFor="let item of activities" [value]="item">{{item | titlecase}}</mat-option>
            </mat-select>
        </div>

        <div class="task">
            <gateway-task-picker [params]="{
                'project-code': project.code,
                'assignee': auth.currentUser().email
            }" (changed)="onSetTask($event)">
            </gateway-task-picker>
        </div>

        <div class="effort">
            <oa-value-editor [value]="{value: timeLog.minutes, unit: { code: 'min' }}" type="span"
                (changed)="onDurationChanged($event)"></oa-value-editor>
        </div>

        <div class="comment">
            <textarea class="oa-input" id="comment" name="comment" maxlength="100"
                [(ngModel)]="timeLog.comment"></textarea>
        </div>

        <div class="action">
            <button mat-icon-button (click)="addNewTimeLog()"
                [disabled]="!timeLog.minutes || !timeLog.activity || !timeLog.date">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </div>
</div>
