<mat-sidenav-container>
  <mat-sidenav *ngIf="entity" class="mat-elevation-z1" [opened]="opened" [mode]="mode" position="end">
    <div class="sections">
      <mat-icon class="close" [matTooltip]="'Hide Sidebar'" (click)="close()" *ngIf="opened && !scrolled">chevron_right
      </mat-icon>
      <!-- <div class="defaultSection" *ngIf="!membersSection && !messagesSection && !activitiesSection">
        <oa-no-data-found [message]="'No Section Selected'"></oa-no-data-found>
      </div> -->
      <ng-container *ngIf="opened || isInitialized">
        <div class="section" *ngIf="memberByRoles.length && task">
          <div class="header" [class.active]="membersSection">
            <i class="members" (click)="membersSection=!membersSection" [class.active]="membersSection"></i>
            <label (click)="membersSection=!membersSection">Members</label>
          </div>
          <div class="body" [class.hidden]="!membersSection">
            <!-- <gateway-task-section *ngIf="task" [view]="'owner'" [properties]="task"></gateway-task-section>
          <gateway-task-section *ngIf="task" [view]="'assignee'" [properties]="task"></gateway-task-section> -->
            <gateway-member-list *ngFor="let role of memberByRoles" [role]="role.roleType" [values]="role.members"
              (selected)="sendEmail($event)" (changed)="onSaveMembers($event)">
            </gateway-member-list>
          </div>
        </div>
        <div class="section" *ngIf="task">
          <div class="header" [class.active]="tasksSection">
            <i class="task" (click)="tasksSection=!tasksSection"></i>
            <label class="spacer" (click)="tasksSection=!tasksSection">Action Items</label>
            <mat-icon class="subtle" *ngIf="tasksSection" (click)="onRefreshTasks()">refresh</mat-icon>
          </div>
          <div class="body" [class.hidden]="!tasksSection">
            <gateway-task-list #taskList *ngIf="task" [parent]="task" [view]="'sideBar'"></gateway-task-list>
          </div>
        </div>
        <div class="section">
          <div class="header" [class.active]="tagSection">
            <i class="tags" (click)="tagSection=!tagSection"></i>
            <label class="spacer" (click)="tagSection=!tagSection">Tags</label>
            <mat-icon class="subtle" *ngIf="tagSection" (click)="chipsDetail.ngOnInit()">refresh</mat-icon>
          </div>
          <div class="body" [class.hidden]="!tagSection" *ngIf="entity">
            <insight-tag-detail #chipsDetail [view]="'chips'" (showSelecter)="tagSectionAdd = $event" [entity]="entity">
            </insight-tag-detail>
            <div class="selectorDiv">
              <insight-tag-types-selector *ngIf="tagSectionAdd" [type]="entity.type" (changed)="addTag($event)">
              </insight-tag-types-selector>
            </div>
          </div>
        </div>
        <div class="section" *ngIf="conversation">
          <div class="header" [class.active]="messagesSection">
            <i class="notes" (click)="messagesSection=!messagesSection"></i>
            <label (click)="messagesSection=!messagesSection">Notes</label>
          </div>
          <div class="body" [class.hidden]="!messagesSection">
            <send-it-new-message [modes]="{chat: true}" [conversation]="conversation"></send-it-new-message>
            <send-it-conversation-detail [view]="'line'" [conversation]="conversation">
            </send-it-conversation-detail>
          </div>
        </div>
        <div class="section">
          <div class="header" [class.active]="activitiesSection">
            <i class="activities" (click)="activitiesSection=!activitiesSection"></i>
            <label class="spacer">Activities</label>
            <mat-icon *ngFor="let item of icons;let i=index" class="lg subtle pointer filter-icons"
              matTooltip="{{item.label}}" (click)="onFilter(item,i)" [class.active]="selectedIndex === i">{{item.icon}}
            </mat-icon>
            <mat-icon class="subtle" *ngIf="activitiesSection" (click)="onRefreshActivities()">refresh</mat-icon>
          </div>
          <div class="body" [class.hidden]="!activitiesSection">
            <insight-journal-list [entity]="entity" [type]="selectedType" [field]="setField" #activityList>
            </insight-journal-list>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-sidenav>
  <div class="controls-row">
    <div class="spacer">
      <ng-content></ng-content>
    </div>
    <div *ngIf="entity && !opened" class="side-bar-toggler mat-elevation-z1 scrollable">
      <ng-container *ngTemplateOutlet="togglerTemplate"></ng-container>
    </div>
  </div>
</mat-sidenav-container>

<ng-template #togglerTemplate>
  <div [class.opened]="opened">
    <button class="openBtn" mat-icon-button>
      <mat-icon [matTooltip]="'Collapse Sidebar'" (click)="open()" *ngIf="!opened">chevron_left</mat-icon>
      <mat-icon [matTooltip]="'Expand Sidebar'" (click)="close()" *ngIf="opened">chevron_right</mat-icon>
    </button>
    <ng-container *ngFor="let section of sections">
      <ng-container *ngIf="section.show">
        <div class="marginTopBottom">
          <i [matTooltip]="section.name" class="md {{section.code}} cursorPointer" [class.active]="section.isSelected"
            (click)="open()"></i>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
